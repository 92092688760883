import React from "react";
import {Button, Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import {withRouter} from "react-router-dom";

const stringSorten = str => {
  const arrayOfString = str ? str.trim().split(" ") : null;
  if (!arrayOfString) return
  if ((arrayOfString && arrayOfString[0].length > 8) || arrayOfString[0].length > 8) {
    return arrayOfString[0];
  }
  return arrayOfString[0] + " " + arrayOfString[1] + " " + arrayOfString[2];
};

const routeToDetails = (props) => {
  const { history } = props;
  history.push(`/search/${props.d.display_name}/category`);
};

const PromoElements = (props) => {
   return(
     <div onClick={() => routeToDetails(props)} className="col-lg-3 col-md-3 col-6 mt-4"
          style={{marginBottom: '1.5rem', cursor: 'pointer'}} key={props.i}>
       <Card>
         <CardImg
           top
           width="100%"
           className="mx-auto car-img img-responsive card-img-home-promo"
           style={{ height: '360px', border: "2px solid whitesmoke", borderRadius: "16px"}}
           src={props.d.cat_image_url}
           alt="image"
         />
         <CardBody style={{ textAlign: 'center', paddingBottom: '0px', paddingLeft: '0.5rem'}}>
           <div className="car-body-wrapper cards-body">
             <CardSubtitle style={{ paddingBottom: "10px", color: "black", fontWeight: 800, fontSize: "1rem"}}>
               {/*eslint-disable-next-line*/}
               <div>
                 {props.d.display_name}
               </div>{' '}
             </CardSubtitle>
           </div>

         </CardBody>
         <div style={{
           padding: '4px 6px',
         }}>
         </div>
         {/*<div style={{*/}
         {/*  textAlign: 'center',*/}
         {/*  color: '#424242',*/}
         {/*  fontSize: '12px',*/}
         {/*  fontWeight: 900,*/}
         {/*  backgroundColor: 'whitesmoke',*/}
         {/*  padding: '0.5rem 0.5rem',*/}
         {/* }}>*/}
         {/*  <span style={{float: 'left'}}> £39.99 USD </span>*/}
         {/*</div>*/}
       </Card>
      </div>
    );

}

export default withRouter(PromoElements);