import React, { Component } from 'react';
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Alert, Button, Card, CardBody, Label} from "reactstrap";
import LinearProgress from '@mui/material/LinearProgress';


class PhoneVerificationConfirm extends React.Component {

  render() {
    const {
      onChange,
      onConfirmCode,
      phoneNumber,
      loader,
      error,
      notification,
      phoneValidationSuccess
    } = this.props;

    return (
      <div>
        <div style={{padding: "0px 8px 8px 8px"}}>
          <div style={{ textAlign: "center" }}>
            { loader ?
              <LinearProgress />
              :
              null
            }
            <Card className="bg-light">
              <CardBody>
                <div className="col-lg-6 col-sm-12 offset-lg-3">
                  <div className="basket-page-information checkout-item-title">
                    Verification
                  </div>
                  {notification &&
                  <div style={{padding: "16px 0px 8px 0px"}}>
                    <Alert color={error ? "danger" : "success"}>
                      {notification}
                    </Alert>
                  </div>
                  }

                  <div>
                    Enter the code that was sent to {phoneNumber} via SMS
                  </div>

                  <AvForm onValidSubmit={onConfirmCode.bind(this,3,false)} style={{padding: '20px'}}>
                    <AvGroup>
                      <Label for="firstName" className="form-input">Verification code</Label>
                      <AvInput
                        id="verificationCode"
                        name="verificationCode"
                        type="text"
                        onChange={onChange}
                        // value={firstN}
                        // onKeyPress={handleKeyPress}
                        placeholder="Enter verification code "
                        disabled={phoneValidationSuccess}
                        maxLength={255}
                      />
                      <AvFeedback>A first name is required to place order.</AvFeedback>
                      <div className="col" style={{paddingTop: '24px'}}>
                        <Button
                          size={"sm"}
                          disabled={loader || phoneValidationSuccess}
                          className="delete-basket-button">
                          Submit
                        </Button>
                      </div>
                    </AvGroup>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
export default PhoneVerificationConfirm;