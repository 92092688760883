import CheckoutGateway from "./../components/CheckoutGateway";
import { connect } from "react-redux"
import {authAction, cartAction, vehicleAction} from "../store/actions";

const mapStateToProps = state => {
  const {
    authReducer: {
      getUserBasketSuccess,
      getUserBasketError,
      getUserBasketLoader,
      isLoggedIn,
      applyDiscountCodeLoader,
      applyDiscountCodeSuccess,
      applyDiscountCodeError,
      getShippingQuoteSuccess,
      getShippingQuoteError,
      getShippingQuoteLoader,
      deleteBasketItemSuccess,
      deleteBasketItemError,
      deleteBasketLoader
    },
    vehicleReducer: {
      getShippingMethodsSuccess,
      addProductToBasketSuccess,
      addProductToBasketError,
      addProductToBasketLoader,
    },
    cartReducer: {
      cartProducts,
      mergeCartSuccess,
      mergeCartLoader
    },
  } = state;
  return {
    getUserBasketSuccess,
    getUserBasketError,
    getUserBasketLoader,
    getShippingMethodsSuccess,
    isLoggedIn,
    applyDiscountCodeLoader,
    applyDiscountCodeSuccess,
    applyDiscountCodeError,
    getShippingQuoteSuccess,
    getShippingQuoteError,
    getShippingQuoteLoader,
    deleteBasketItemSuccess,
    deleteBasketItemError,
    deleteBasketLoader,
    addProductToBasketSuccess,
    addProductToBasketError,
    addProductToBasketLoader,
    cartProducts,
    mergeCartSuccess,
    mergeCartLoader

  };
};

const mapDispatchtoProps = dispatch => {
  return {
    getUserBasket: payload =>
      dispatch(authAction.getUserBasket(payload)),
    addProductToBasket: payload =>
      dispatch(vehicleAction.addProductToBasket(payload)),
    mergeCart: payload =>
      dispatch(cartAction.mergeCart(payload)),
    getShippingQuote: payload =>
      dispatch(authAction.getShippingQuote(payload)),
    deleteBasketItem: payload =>
      dispatch(authAction.deleteBasketItem(payload)),
    discountCodeApplication: payload =>
      dispatch(authAction.discountCodeApplication(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(CheckoutGateway)