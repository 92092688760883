import {
  REGISTER_USER,
  API_TOKEN_AUTH,
  GET_USER_PROFILE,
  API_TOKEN_DESTROY,
  SOCIAL_API_TOKEN,
  GET_USER_BASKET,
  GET_USER_ORDERS,
  PASSWORD_RESET,
  PASSWORD_RESET_CONFIRM,
  ACTIVATE_ACCOUNT_CONFIRM,
  RESEND_ACTIVATION_LINK,
  DISCOUNT_CODE_APPLICATION,
  GET_AGENT_PROFILE,
  UPGRADE_TO_AGENT_ACCOUNT,
  GET_SHIPPING_QUOTE,
  GET_DOWNLOAD_FILE,
  UPDATE_TRAINING_PROGRESS,
  MERGE_CART,
  ADD_PRODUCT_TO_BASKET, DELETE_BASKET_ITEM, API_PHONE_VALIDATION, API_PHONE_VALIDATION_CONFIRM
} from "../constants";
import { Observable } from "rxjs/Rx";
import {authAction, cartAction } from "../actions/index";
import { HttpService } from "../../services/http";
import path from "../../config/path";

export default class authEpic {
    // Register User
    static registerUser = action$ =>
        action$.ofType(REGISTER_USER).switchMap(({ payload }) => {
            return HttpService.post(`${path.REGISTER_USER}`, payload)
              .switchMap(response => {
                if (response.status === 201) {
                  return Observable.of(authAction.registerUserSuccess({ activeLogin: 2 }));
                }
                else {
                  return Observable.of(authAction.registerUserFailure({ error: true, message: response.data }));
                }
              })
              .catch(err => {

                  if (err && err.response && err.status === 400) {

                    if (err.response.email) {
                      return Observable.of(authAction.registerUserFailure({ error: true, message: err.response.email }));
                    }
                      return Observable.of(authAction.registerUserFailure({ error: true, message: "Error!" }));
                  }
                  else {
                      return Observable.of(authAction.registerUserFailure({ error: true, message: "Error! try later" }));
                  }
              });
        });

    // Account Activate Confirm
    static activateAccountConfirm = action$ =>
      action$.ofType(ACTIVATE_ACCOUNT_CONFIRM).switchMap(({ payload }) =>{
        return HttpService.get(path.ACCOUNT_CONFIRM + payload.uid + '/' + payload.token + '/')
          .switchMap(response => {
            if (response.status === 200){
              if (response.response['response status'] === "411") {
                return Observable.of(
                  authAction.activateAccountFailure(response.response)
                )
              }
              return Observable.of(authAction.activateAccountConfirmSuccess(response.response))
            }
          }).catch( err => {
              return Observable.of(
              authAction.activateAccountFailure(`${err}`))
            });
      });

    // static
    static resendActivationLink = action$ =>
      action$.ofType(RESEND_ACTIVATION_LINK).switchMap(({ payload }) =>{
        return HttpService.post(path.RESEND_ACTIVATION_LINK, payload)
          .switchMap(response => {
            if (response.status === 200) {
              return Observable.of(authAction.resendActivationLinkSuccess(response.response))
            }
          }).catch(err => {
            return Observable.of(
              authAction.resendActivationLinkFailure(err))
          });
    })

    // Api Token Auth
    static apiTokenAuth = action$ =>
      action$.ofType(API_TOKEN_AUTH).switchMap(({ payload }) => {
        return HttpService.post(path.API_TOKEN_AUTH, payload.credentials)
          .switchMap(response => {
            if (response.status === 200 | response.status === 204 && response.response.token ) {
              localStorage.setItem("token", response.response.token)
              localStorage.setItem("email", payload.credentials.username)

              if (payload.location && payload.location.state && payload.location.state.redirect) {
                  const { redirect: { pathname }, destinationCountry, destinationPort } = payload.location.state;
                  payload.history.push(pathname, { destinationCountry, destinationPort });
              }
              return Observable.of(
                authAction.apiTokenAuthSuccess(true)
              )
            }
          })
          .catch(err => {
            if (err && err.response && err.status === 400) {
              if (err.response.non_field_errors) {
                return Observable.of(authAction.apiTokenAuthFailure({ error: true, message: err.response.non_field_errors }));
              }
              return Observable.of(authAction.apiTokenAuthFailure({ error: true, message: "An error has occurred. Please try again or contact customer service" }));
            }
            else {
              return Observable.of(authAction.apiTokenAuthFailure({ error: true, message: err }));
            }
          });
      });

    // Social API Token
  static socialApiAuth = action$ =>
    action$.ofType(SOCIAL_API_TOKEN).switchMap(({ payload }) => {
      return HttpService.post(path.SOCIAL_API_AUTH, payload.payload)
        .switchMap(response => {
          if (response.status === 200) {
            localStorage.setItem("token", response.response.token)
            localStorage.setItem("email", response.response.email)
          return Observable.of(
            authAction.apiTokenAuthSuccess(true)
          )
          }
        })
        .catch(err => {

          return Observable.of(authAction.apiTokenAuthFailure({ error: true, message: err }));
        });
    });

    // Password reset
    static passwordReset = action$ =>
      action$.ofType(PASSWORD_RESET).switchMap(({ payload }) => {
        return HttpService.post(path.RESET_PASSWORD, payload)
          .switchMap( response => {
            if (response.status === 200 ) {
              return Observable.of(
                authAction.passwordResetSuccess(response.response)
              );
            }
          }).catch(err => {
            return Observable.of(
              authAction.passwordResetFailure(`${err}`))
          });
      });

    // Password reset
      static passwordResetConfirm = action$ =>
        action$.ofType(PASSWORD_RESET_CONFIRM).switchMap(({ payload }) => {
          return HttpService.post(path.RESET_PASSWORD_CONFIRM + payload.uid + '/' + payload.token + '/', payload)
            .switchMap( response => {
              if (response.status === 200 ) {
                return Observable.of(
                  authAction.passwordResetConfirmSuccess(response.response)
                );
              }
            }).catch(err => {
              return Observable.of(
                authAction.passwordResetConfirmFailure(`${Object.values(err.response)}`))
            });
        });


    // Logout
    static apiTokenLogout = action$ =>
      action$.ofType(API_TOKEN_DESTROY).switchMap(({ payload }) => {
        const token = localStorage.getItem('token')
        return HttpService.post(path.API_TOKEN_DESTROY, {}, {"Authorization": "Token "+ token})
          .switchMap(response => {
            if (response.status === 200 | response.status === 204 ) {
                // document.cookie = `token=; expires=${+new Date()}; domain=localhost; path=/`;
                // document.cookie = `email=; expires=${+new Date()}; domain=localhost; path=/`;
              localStorage.clear();
              return Observable.of(
                authAction.apiTokenLogoutSuccess(response.response),
              );
            }
            else {
                return Observable.of(authAction.apiTokenLogoutFailure({"Error": "Not logged out"}));
            }
          })
          .catch(err => {
            if (err && err.response && err.status === 400) {
              localStorage.clear();
              return Observable.of(authAction.apiTokenLogoutFailure({"Error": "400 - Not logged out"}));
            }
            else {
              localStorage.clear();
              return Observable.of(authAction.apiTokenLogoutSuccess({"Reason": "Invalid Token"}));
            }
          });
        });

    // Get Download File
    static getDownloadFile = action$ =>
      action$.ofType(GET_DOWNLOAD_FILE).switchMap(({payload}) => {
        let headers = {
           "Content-Type": "application/json",
                ...payload
        }

        return HttpService.get(path.GET_DOWNLOAD, headers)
          .switchMap(response => {
            if (response.status === 200) {
              response = response.blob()
                .then((blob) => {
                  // 2. Create blob link to download
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'sample.pdf');
                  // 3. Append to html page
                  document.body.appendChild(link);
                  // 4. Force download
                  link.click();
                  // 5. Clean up and remove the link
                  link.parentNode.removeChild(link);
                })
              return Observable.of(
                authAction.getDownloadFileSuccess(response)
              )
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.getDownloadFileFailure(`${err}`)
            )
          });
      });

    // Update Training Progress
    static updateTrainingProgress = action$ =>
      action$.ofType(UPDATE_TRAINING_PROGRESS).switchMap(({ payload }) => {
         const token = localStorage.getItem('token')
        let authData = {Authorization: `token ${token}`}
        let headers = {
          "Content-Type": "application/json",
          ...authData
        }
        return HttpService.get(path.UPDATE_TRAINING_PROGRESS + "?c=" + payload.Chapter, headers)
          .switchMap(response=> {
            if (response.status === 200) {
              return Observable.of(
                authAction.updateTrainingProgressSuccess(response.response)
              );
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.updateTrainingProgressFailure(`${err}`));
          });
      });


    // Get User Profile
    static getUserProfile = action$ =>
        action$.ofType(GET_USER_PROFILE).switchMap(({ payload }) => {

            let headers = {
                "Content-Type": "application/json",
                ...payload
            }

            return HttpService.get(path.GET_USER_PROFILE, headers)
                .switchMap(response => {
                    
                    if (response.status === 200) {
                        return Observable.of(
                            authAction.getUserProfileSuccess(response.response)
                        );
                    }
                })
                .catch(err => {
                    return Observable.of(
                        authAction.getUserProfileFailure(`${err}`));
                });
        });

    static getAgentProfile = action$ =>
      action$.ofType(GET_AGENT_PROFILE).switchMap(({payload}) => {
        let headers = {
          "Content-Type": "application/json",
          ...payload
        }
        return HttpService.get(path.GET_AGENT_PROFILE, headers)
          .switchMap(response => {
            if (response.status === 200) {
              return Observable.of(
                authAction.getAgentProfileSuccess(response)
              )
            }
            if (response.status === 204) {
              return Observable.of(
                authAction.getAgentProfileSuccess({status: 204})
              )
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.getAgentProfileFailure(`${err}`));
          });
      });

    static upgradeToAgentAccount = action$ =>
      action$.ofType(UPGRADE_TO_AGENT_ACCOUNT).switchMap(({ payload }) => {
        const token = localStorage.getItem('token')

        return HttpService.post(path.UPGRADE_TO_AGENT, {mobileNumber: payload.mobileNumber,
          referrerEmail: payload.referrerEmail}, {"Authorization": "Token "+ token})
          .switchMap(response => {
            if (response.status === 200){

              return Observable.of(
              authAction.upgradeToAgentAccountSuccess(response.response)
              )}
          })
          .catch(err => {

            return Observable.of(
              authAction.upgradeToAgentAccountFailure(`${err}`));
          });
      });

    // Get User Orders
    static getUserOrders = action$ =>
      action$.ofType(GET_USER_ORDERS).switchMap(({ payload }) => {
        let headers = {
          "Content-Type": "application/json",
          ...payload
        }

        return HttpService.get(path.GET_USER_ORDERS, headers)
          .switchMap(response => {
            if (response.status === 200) {
              return Observable.of(
                authAction.getUserOrdersSuccess(response.response)
              );
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.getUserOrdersFailure(`${err}`));
          })
      })

    // Delete Basket Item
    static deleteBasketItem = action$ =>
      action$.ofType(DELETE_BASKET_ITEM).switchMap(({payload}) => {
        const token = localStorage.getItem('token')
        let itemUrl = payload.url
        if (process.env.REACT_APP_API_ENDPOINT === 'prod') {
          itemUrl = itemUrl.replace('http', 'https');
        }
        return HttpService
          .delete(itemUrl,{"Authorization": "Token "+ token})
          .switchMap( response => {
            if(response.status === 204) {
              return Observable.of(
                authAction.deleteBasketItemSuccess("Victory is sweet")
              );
            }
          })
          .catch(err => {
            return Observable.of(authAction.deleteBasketItemFailure(`${err}`))
          });
      });

    // Merge Baskets
    static mergeCart = action$ =>
      action$.ofType(MERGE_CART).switchMap(({payload}) => {
        // const token = localStorage.getItem('token')
        return HttpService
          .post(path.MERGE_CART, payload )
          .switchMap(response => {
            if (response.status === 200) {
              return Observable.of(
                cartAction.mergeCartSuccess(response.response)
              );
            }
            else {
              return Observable.of(
                cartAction.mergeCartFailure("Something went wrong!")
              )
            }
          })
          .catch(err => {
            return Observable.of(cartAction.mergeCartFailure("Unable to merge cart"))
          });
      });


  static getUserBasket = action$ =>
      action$.ofType(GET_USER_BASKET).switchMap(({ payload }) => {
        let headers = {
          "Content-Type": "application/json",
          ...payload
        }

        return HttpService.get(path.GET_USER_BASKET, headers, headers)
          .switchMap(response => {
            if (response.status === 200) {
              let endpoint = response.response.lines
              if (process.env.REACT_APP_API_ENDPOINT === 'prod' || process.env.REACT_APP_API_ENDPOINT ==='staging') {
                endpoint = endpoint.replace('http', 'https');
              }
              let total = response.response.total_incl_tax
              let status = response.response.status
              let shipping_requested = response.response.is_shipping_requested
              let basketID = response.response.id
              let payPalObj = response.response.pp_obj
              let deliveryCharge = response.response.delivery_charge
              let deliverycode = response.response.delivery_code
              return HttpService.get(endpoint, headers)
                .switchMap(response => {
                if (response.status === 200) {
                  response.response['total'] = total
                  response.response['status'] = status
                  response.response['is_shipping_requested'] = shipping_requested
                  response.response['basketID'] = basketID
                  response.response['payPalObj'] = payPalObj
                  response.response['deliveryCharge'] = deliveryCharge
                  response.response['deliveryCode'] = deliverycode
                  return Observable.of(
                    authAction.getUserBasketSuccess(response.response)
                  );
                }
              })
            }
          })
          .catch( err => {
            return Observable.of(
              authAction.getUserBasketFailure(`${err}`));
          });
      });

    static getShippingQuote = action$ =>
      action$.ofType(GET_SHIPPING_QUOTE).switchMap(({ payload }) => {
        const token = localStorage.getItem('token')
        let authData = {Authorization: `token ${token}`}
        let headers = {
          "Content-Type": "application/json",
          ...authData
        }
        return HttpService.get(path.GET_SHIPPING_QUOTE + "?q=" + payload.basketID, headers)
          .switchMap(response => {
            if (response.status === 200) {
              return Observable.of(
                authAction.getShippingQuoteSuccess(response)
              )
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.getShippingQuoteFailure(`${err}`));
          })
      })

    static discountCodeApplication = action$ =>
      action$.ofType(DISCOUNT_CODE_APPLICATION).switchMap(({ payload }) => {
        const token = localStorage.getItem('token')
        return HttpService.post(path.APPLY_DISCOUNT, payload, {"Authorization": "Token " + token})
          .switchMap(response => {
            if (response.status === 200) {
              return Observable.of(
                authAction.discountCodeApplicationSuccess(response)
              )
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.discountCodeApplicationFailure(`${err}`));
          })
      })

    static validatePhone = action$ =>
      action$.ofType(API_PHONE_VALIDATION).switchMap(({payload}) => {
        return HttpService.post(path.PHONE_VALIDATION, payload)
          .switchMap(response => {
            if (response.status === 200) {
              localStorage.setItem("phone_session_token", response.response.session_token)
              return Observable.of(
                authAction.validatePhoneSuccess(response)
              )
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.validatePhoneFailure(`${err}`)
            )
          })
      })

    static validatePhoneConfirm = action$ =>
      action$.ofType(API_PHONE_VALIDATION_CONFIRM).switchMap(({payload}) => {
        return HttpService.post(path.PHONE_VERIFY, payload)
          .switchMap(response => {
            if (response.status === 200) {
              return Observable.of(
                authAction.validatePhoneConfirmSuccess(response)
              )
            }
          })
          .catch(err => {
            return Observable.of(
              authAction.validatePhoneConfirmFailure(`${JSON.stringify(err.response)}`)
            )
          })
      })


}


  // static GET_BASKET_DETAIL= `${credentials.API_URL}/promotional-homepage/`;