import React, { Component, useRef } from 'react';
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import {Button, Label, Card, CardBody} from "reactstrap";
import LinearProgress from "@mui/material/LinearProgress";


class PhoneVerification extends React.Component {
  componentDidMount() {
    const { method } = this.props;
    if (method === "Cash Payment") {
    }
  }

  render() {
    const { method, onChange, onValidatePhone, buttonDisabled, loader } = this.props;

    return (
      <div>
        <div style={{padding: "0px 8px 8px 8px", textAlign: "center"}}>
          { loader ?
            <LinearProgress />
            :
            null
          }
          {method === "Card Payment"
            ?
            <div>
            </div>
          :
            <div>
              <Card className="bg-light">
                <CardBody>
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                    <div className="basket-page-information checkout-item-title">
                      {method}
                    </div>
                    Enter your mobile number to verify your order
                    <AvForm onValidSubmit={onValidatePhone.bind(this)} style={{padding: "20px"}}>
                      <Label for="verifyNumber" className="form-input">Contact Number</Label>
                      <AvField class="form-input"
                               onChange={onChange}
                               name="verifyNumber"
                               disabled={buttonDisabled}
                        // placeholder="Mobile number"
                               type="text" validate={{
                        required: {value: true, errorMessage: 'Please enter your mobile number'},
                        // pattern: {value: '^[0-9]+$', errorMessage: 'Your number must be composed only of numbers'},
                        minLength: {value: 5, errorMessage: 'Your number must be between 5 and 16 characters'},
                        maxLength: {value: 16, errorMessage: 'Your number must be between 5 and 16 characters'}
                      }} />
                      <div className="col" style={{paddingTop: "16px"}}>
                        <Button
                          size={"sm"}
                          disabled={buttonDisabled}
                          className="delete-basket-button">
                          Get code
                        </Button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>

            </div>
          }
        </div>
      </div>
    );
  }
}
export default PhoneVerification;