import BestDeals from './../components/BestDeals';
import { connect } from "react-redux";
import { vehicleAction } from "./../store/actions";

const mapStateToProps = state => {
    const {
        vehicleReducer: {
            // getBestDealsSuccess,
            // getBestDealsError,
            // getBestDealsLoader,
            getPromotionalApiDataSuccess,
            getPromotionalApiDataError,
            getPromotionalApiDataLoader,
            getFooterApiDataSuccess,
            getFooterApiDataError,
            getFooterApiDataLoader,
            getCategoriesApiDataLoader,
            getCategoriesApiDataSuccess,
            getCategoriesApiDataError
        }
    } = state;
    return {
        // getBestDealsSuccess,
        // getBestDealsError,
        // getBestDealsLoader,
        getPromotionalApiDataSuccess,
        getPromotionalApiDataError,
        getPromotionalApiDataLoader,
        getFooterApiDataSuccess,
        getFooterApiDataError,
        getFooterApiDataLoader,
        getCategoriesApiDataLoader,
        getCategoriesApiDataSuccess,
        getCategoriesApiDataError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // getBestDeals: _ =>
        //     dispatch(vehicleAction.getBestDeals()),
        getPromotionalApiData: _ =>
            dispatch(vehicleAction.getPromotionalApiData()),
        getFooterApiData: _ =>
            dispatch(vehicleAction.getFooterApiData()),
        getCategoriesApiData: _ =>
            dispatch(vehicleAction.getCategoriesApiData())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BestDeals);