import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/index";
import rootEpic from "./epics/index";
import { persistReducer } from 'redux-persist'
import LocalStorage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'xxxx-key-xxxx',
  storage: LocalStorage,
  // blacklist: ['vehicleReducer']
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const epicMiddleware = createEpicMiddleware();

const logger = createLogger({
  collapsed: true
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(epicMiddleware, logger))
);

if (process.env.REACT_APP_API_ENDPOINT==="prod") {
  store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(epicMiddleware))
);
}


epicMiddleware.run(rootEpic);

export default store;
