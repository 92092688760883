import React, {  } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  Table,
  TabPane,
  Card,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  CardImg,
  Button,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withCookies } from 'react-cookie';
import classnames from 'classnames';
import credentials from "../../config/credentials"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-light-accordion/demo/css/index.css'
import Stepper from "react-stepper-horizontal";
import TermsAndConditions from "../AgentsTermsAndConditions"
import AgentSuccess from "../AgentSuccessComponent"
import routeTo from "./../helpers/RouteTo"
import {Loading} from "../LoadingComponent";
import {OrderListComponent} from "./OrderListComponent"
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Accordion from 'react-bootstrap/Accordion'
import SlidingPane from "react-sliding-pane";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const missingImage = require("../../media/image_not_found.jpg")

class Profile extends React.Component {

  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.upgradeToAgent = this.upgradeToAgent.bind(this)
    this.stageOneUpgrade = this.stageOneUpgrade.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.stageTwoUpgrade = this.stageTwoUpgrade.bind(this)
    this.viewDashboard = this.viewDashboard.bind(this)
    this.routeToDownline = this.routeToDownline.bind(this)
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      country: "",
      date_joined: "",
      activeTab: "1",
      results: [],
      currentPage: 1,
      isAgent: false,
      discountCode: "",
      discountLevel: "",
      discountCodeUsage: {},
      mobileNumber: "",
      value: "",
      error: false,
      message: "",
      multiView: 0,
      openAgentAccount: false,
      activeStep: 1,
      isChecked: false,
      code: "",
      referrerEmail: "",
      activeKey: "0",
      open: false,
      currentPropLine: "",
      currentAddress: ""
    }
  }

  componentDidMount() {

    const { history, location, isLoggedIn } = this.props;
    // const token = cookies.get('token') || "";
    const token = localStorage.getItem('token')
    if (!isLoggedIn) {
      history.push("/login", location);
    }

    let headers = { Authorization: `token ${token}` };

    this.props.getUserProfile && this.props.getUserProfile(headers);
    this.props.getUserOrders && this.props.getUserOrders(headers);
    this.props.getAgentProfile && this.props.getAgentProfile(headers);
  }

  // TODO move this request to actioner
  makeHttpRequestWithPage = async pageNumber => {

    const token = localStorage.getItem('token')
    let heads = { Authorization: `token ${token}` };

    let response = await fetch(`${credentials.API_URL}/orders/?page=${pageNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...heads
      },
    });

    const data = await response.json();

    this.setState({ ...data, currentPage: pageNumber});
  }

   handleChange(event) {
      const target = event.target;
      const name = target.name;
      this.setState({
          [name]: target.value
      })
  }

  handleSelect(e) {
    this.setState({
      activeKey : e
    });
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  handleClickOpen(lineProps, address) {
    this.setState({
      open: true,
      currentPropLine: JSON.stringify(lineProps),
      currentAddress: address.search_text
    })
  }


  handleCheck(event) {
    const isActive = event.target.checked;
    this.setState({ isChecked: isActive });
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.getUserProfileSuccess && prevProps.getUserProfileSuccess !== this.props.getUserProfileSuccess) {
      this.setState({ ...this.props.getUserProfileSuccess })
    }
    if (this.props.getAgentProfileSuccess && prevProps.getAgentProfileSuccess !== this.props.getAgentProfileSuccess) {
      const statusCode = this.props.getAgentProfileSuccess.status
      if (statusCode === 204) {
        this.setState({isAgent: false})
      } else if (statusCode === 200) {

        let response = this.props.getAgentProfileSuccess.response
        this.setState({isAgent: true,
                              discountCode: response.code,
                              discountLevel: response.name,
                              discountCodeUsage: response.voucher_application_instances,
                              discountCodeStartTime: response.start_datetime})
      }
    }
    if (this.props.getUserOrdersSuccess && prevProps.getUserOrdersSuccess !== this.props.getUserOrdersSuccess) {
      this.setState({ ...this.props.getUserOrdersSuccess })
    }
    if (this.props.getUserProfileError === "AjaxError: ajax error 401" ) {
      const { history, location } = this.props;
      history.push("/login", location);
    }
    if (this.props.upgradeToAgentAccountSuccess && prevProps.upgradeToAgentAccountSuccess !== this.props.upgradeToAgentAccountSuccess) {
      const agent_code = this.props.upgradeToAgentAccountSuccess.code
      this.setState({multiView: 3, activeStep: 3, code: agent_code})
    }
    if (this.props.upgradeToAgentAccountError && prevProps.upgradeToAgentAccountError !== this.props.upgradeToAgentAccountError) {
       if (this.props.upgradeToAgentAccountError === "AjaxError: ajax error 406") {
        this.setState({error: true, message: "Oops - the referrer email address entered is not registered on Ex UK Online. Rectify or delete the email address to proceed"})
        return;
      } else if (this.props.upgradeToAgentAccountError === "AjaxError: ajax error 409") {
         this.setState({error: true, message: "Oops - the referrer email address must be different to yours. Rectify or delete the email address to proceed"})
        return;
       } else {
        this.setState({error: true,
        message: "Oops - Something has gone wrong. Refresh your browser to revert to your last saved state",
        isReadyToSubmit: false, referrerEmail: ""})
       }
    }

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({activeTab: tab})
    }
  }

  upgradeToAgent () {
    this.setState({multiView: 1, openAgentAccount: true, activeStep: 1})
  }

  routeToDownline (page) {
    const {history} = this.props
    if (page === 'Downline') {
      history.push({
        pathname: "/my-downline",
      })
    }
    if (page === 'Advertising') {
      history.push({
        pathname: "/advertising-tools",
      })
    }
    if (page === 'Training') {
      history.push({
        pathname: "/supporter-training",
      })
    }
  }


  stageOneUpgrade () {
    if (!isPossiblePhoneNumber(this.state.mobileNumber)){
      this.setState({ error: true, message: "Please enter a valid mobile number" })
      return
    } else {
      this.setState({multiView: 2, activeStep: 2, message: ""})
    }
  }

  stageTwoUpgrade () {
    const refEmail = this.state.referrerEmail
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    if ((refEmail.length > 0) && (!EMAIL_REGEXP.test(String(refEmail).toLowerCase()))) {
      return;
    }

    const payload = {
      mobileNumber: this.state.mobileNumber,
      referrerEmail: this.state.referrerEmail,
    }

    this.props.upgradeToAgentAccount && this.props.upgradeToAgentAccount(payload)
  }

  viewDashboard() {
    window.location.reload()
  }

  renderResults(currentResult, getUsersOrdersLoader) {
    if (currentResult && currentResult.length) {
      return currentResult.map(
        ({ status, line_props, date_placed, total_incl_tax, number, shipping_address }, i) => {
          const ordImage = `${line_props[0].image}`
          return (
            <div className="col-12 col-md-12 col-lg-12 mt-12 search-card" key={i}>
              <Card className="cardSearch">
                <div>
                  <div className="row">
                    <div className="col col-sm-4 col-md-2 col-lg-3 search-card-img-div">
                      <CardImg
                        src={ordImage}
                        className="mx-auto car-img img-responsive card-img-top"
                        id="search-card-imgsdf"
                      />
                    </div>
                    <div className="col col-sm-8 col-md-6 col-lg-6 contentTitles">
                      <div className="topPriceContainer">
                        <span className="price">
                          £ {total_incl_tax}
                        </span>
                      </div>
                      <div>
                        <span style={{padding: '4px'}}
                             className={"statusCardProperties-" + ((status === 'Pending')
                               ? ('pending') : ((status === 'Canceled') ? ('canceled') : ('authorized')))}>
                          STATUS: {status}
                        </span>
                      </div>

                      <CardTitle className="cardSearchTitle">Order No: {number}</CardTitle>
                      <div className="container ordersCardProperties">
                        Order date: {date_placed && moment(date_placed).format('MMM. D, YYYY')}
                      </div>
                      <div>
                        <Button variant="outlined" onClick={() => this.handleClickOpen(line_props, shipping_address)}>
                          View Order
                        </Button>
                      </div>
                    </div>
                    <div className="col col-sm-3 col-md-3 col-lg-3 d-flex flex-column justify-content-between align-items-center price-container">
                      <span className="price">
                        £{total_incl_tax}
                      </span>
                    </div>
                  </div>
                </div>

              </Card>
          </div>

          )
        }
      );
    } else {
      return (
        <div className="col-12 col-md-12 col-lg-12 mt-12">
          <Card>No record Found</Card>
        </div>
      );
    }
  }


  render() {
    let trainingComplete = false
    const { first_name, last_name, email, country, date_joined, results,
      isAgent, discountCode, discountLevel, discountCodeUsage, discountCodeStartTime,
      message, multiView, openAgentAccount, activeStep, code, agent_training } = this.state;

    const { getUserOrdersLoader, upgradeToAgentAccountLoader, history } = this.props
    const arr = ["intro", "chapter_1", "chapter_2", "chapter_3"];
    if (isAgent) {
      if (agent_training == null) {
        trainingComplete = false
      } else {
        let progress = JSON.parse(agent_training)
        if (Object.values(progress).includes(false) ||
          !(arr.every(item => progress.hasOwnProperty(item)))) {
          trainingComplete = false
        } else {
          trainingComplete = true
        }
      }
    }
    let renderPageNumbers;

    const pageNumbers = [];

    if (this.state.count !== null) {
      for (let i = 1; i <= Math.ceil(this.state.count / 25); i++) {
        pageNumbers.push(i);
      }

      renderPageNumbers = pageNumbers.map(number => {
        let classes = this.state.currentPage === number ? "pagination active" : '';
        return (
          <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}>{number}</span>
        );
      });
    }

    return (
      <div>

      <div className="container cpanel">
        <Breadcrumb style={{marginBottom: '0rem'}}>
          <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to="" >Account</Link></BreadcrumbItem>
          <BreadcrumbItem active>My Account</BreadcrumbItem>
        </Breadcrumb>
        {isAgent ?
          trainingComplete ?
          null
          :
          <div style={{minHeight: '25px',
                    backgroundColor: 'yellow',
                    textAlign: 'center',
                    paddingTop: '5px',
                    fontWeight: 600,
                    color: '#424242',
                    paddingBottom: '5px'}}>
            Congratulations, you've been approved on the Supporter Programme.
            Go to your dashboard to complete your training and earn 100 points now!</div>

        :
          null
        }

        <div className="row" style={{marginTop: '1rem'}}>
          <div className="page-header col-12">
            <h4 className="page-header-content">My Account</h4>
            <hr className="fancy-line" />
          </div>
          <OrderListComponent
            selectedValue={"selectedValue"}
            open={this.state.open}
            onClose={this.handleClose}
            propLine={this.state.currentPropLine}
            address={this.state.currentAddress}
          />
        </div>

      {openAgentAccount ?
      (
        <div>
          <Stepper steps={ [{title: 'Payment'}, {title: 'Terms & Conditions'}, {title: 'Join'}] }
                   activeStep={ activeStep }
                   size={30}
                   circleFontSize={10}
                   titleFontSize={12}
                   circleTop={0}
                   activeColor={'#989798'}
                   completeColor={'#3e5067'}
              />
        {multiView === 1 &&
          <div>
            <div style={{ marginTop: 20, paddingBottom: 40}}>
              <Row>
                <div className="col-12">
                  <div style={{textAlign: 'center'}}>
                    {message &&
                    <Alert color="danger" style={{display: 'inline-block'}}>
                      {message}
                    </Alert>}
                  </div>
                </div>
              </Row>
              <Row style={{paddingBottom: '20px'}}>
                <Col sm={12} md={12} lg={12} className="page-information" style={{textAlign: 'center', fontSize: '14px'}}>
                   Your earned commission will be sent to your phone wallet, please enter your mobile number :
                </Col>
              </Row>
              <Row style={{paddingBottom: '20px'}}>
                <Col className="col-sm-12 basket-page-information" style={{textAlign: 'center'}}>
                  <div style={{maxWidth: '200px', margin: 'auto', fontSize: '14px'}}>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={ this.state.mobileNumber }
                      onChange={ mobileNumber => this.setState({ mobileNumber }) } />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} style={{textAlign: 'center'}}>
                  <Button className="delete-basket-button" size="sm" onClick={() => this.stageOneUpgrade()}>Next</Button>
                </Col>
              </Row>
            </div>
          </div>
        }
        {multiView === 2 &&
          <div>
            {upgradeToAgentAccountLoader ? (
              <div style={{paddingTop: '10px'}}>
                <Loading />
              </div>
            )
            :
            (
            <div style={{ marginTop: 20, paddingBottom: 40}}>
              <Row>
                <div className="col-12">
                  <div style={{textAlign: 'center'}}>
                    {message &&
                    <Alert color="danger" style={{display: 'inline-block'}}>
                      {message}
                    </Alert>}
                  </div>
                </div>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} className="page-information" style={{textAlign: 'center', fontSize: '14px'}}>
                  Did someone you know refer you to Ex UK Online? If so, please enter their email address below.
                </Col>
              </Row>
              <Row style={{paddingBottom: '20px'}}>
                <Col sm={12} md={12} lg={12} className="page-information" style={{textAlign: 'center', fontSize: '14px'}}>
                  <AvForm style={{display: 'inline-block', fontSize: '14px'}}>
                    <AvGroup>
                      <AvInput
                        name="referrerEmail"
                        type="email"
                        onChange={this.handleChange}
                        placeholder="Referrer Email (optional)"
                        maxLength={355}
                        style={{ fontSize: '14px'}}
                        value={this.state.referrerEmail}
                      />
                    <AvFeedback>A valid email is required.</AvFeedback>
                    </AvGroup>
                  </AvForm>
                </Col>


              </Row>
              <Row style={{paddingBottom: '20px'}}>
                <Col sm={12} md={12} lg={12} className="page-information" style={{textAlign: 'center', fontSize: '13px'}}>
                  <div style={{paddingBottom: '10px', fontSize: '14px', fontWeight: 600}}>Terms & Conditions</div>
                  <TermsAndConditions/>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} className="page-information" style={{textAlign: 'center', fontSize: '13px'}}>
                  {/*<label style={{padding: '0px 20px'}}>*/}
                  {/*<input*/}
                  {/* type="checkbox"*/}
                  {/* onChange={(event) => this.handleCheck(event)}*/}
                  {/* checked={this.state.isChecked}*/}
                  {/* id="checkbox_id"*/}
                  {/* name="iAgree"*/}
                  {/*/>*/}
                  {/*  <div> I agree to the Terms and Conditions </div>*/}
                  {/*</label>*/}
                  <input
                    type="checkbox"
                    id="check3"
                    checked={this.state.isChecked}
                    onChange={(event) => this.handleCheck(event)}
                  />
                  <label style={{padding: '0px 10px'}} htmlFor="check3">I agree to the terms and conditions</label>


                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} style={{textAlign: 'center'}}>
                  <Button className="delete-basket-button" size="sm" disabled={!this.state.isChecked} onClick={() => this.stageTwoUpgrade()}>Start Earning Now</Button>
                </Col>
              </Row>
            </div>
            )}
          </div>
        }

        {multiView === 3 &&
          <div style={{textAlign: "center", paddingTop: '30px', paddingBottom: "100px"}}>

            <AgentSuccess/>
            <div className="page-header-content" style={{textAlign: "center", paddingTop: '20px', paddingBottom: "20px"}}>
              Your code is: {code}
            </div>
            <Row>
                <Col sm={12} md={12} lg={12} style={{textAlign: 'center'}}>
                  <Button className="delete-basket-button" size="sm" disabled={!this.state.isChecked} onClick={() => this.viewDashboard()}>View Dashboard</Button>
                </Col>
            </Row>

          </div>
        }

        </div>


      ):(
        <div style={{ marginTop: 20, paddingBottom: 40}}>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                      Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >
                      Order History
                  </NavLink>
                </NavItem>
              </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Table bordered >
                          <tbody>
                            <tr>
                              <td className="tbl-header">Name</td>
                              <td className="tbl-data">{first_name} {last_name}</td>
                            </tr>
                            <tr>
                              <td className="tbl-header">Email</td>
                              <td className="tbl-data">{email}</td>
                            </tr>
                            <tr>
                              <td className="tbl-header">Postcode</td>
                              <td className="tbl-data">{country}</td>
                            </tr>
                            <tr>
                              <td className="tbl-header">Date Joined</td>
                              <td className="tbl-data">{date_joined && moment(date_joined).format('MMM. D, YYYY, h:mm A')}</td>
                            </tr>
                          </tbody>
                        </Table>
                        {/*TODO Addition functionality to my account*/}
                        {/*<div className="row">*/}
                        {/*  <div className="col-lg-6 d-none d-lg-block ">*/}
                        {/*    <Button color="primary" to={'/'} block>Change Password</Button>*/}
                        {/*  </div>*/}
                        {/*  <div className="col-lg-6 col-12" style={{ marginBottom: "20px" }}>*/}
                        {/*    <Button color="primary" block>Edit Profile</Button>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      {results && this.renderResults(results, getUserOrdersLoader)}
                    </Row>

                    <div className="pagination">
                      {renderPageNumbers}
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
        </div>
      )}
      </div>



      </div>

    )
  }
}

export default withCookies(Profile);
