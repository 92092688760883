import { combineEpics } from 'redux-observable';
import authEpic from './authEpic'
import vehicleEpic from './vehicleEpic'
import searchEpic from './searchEpic'

const rootEpic = combineEpics(

    // Register User Epic
    authEpic.registerUser,

    // Account Activate
    authEpic.activateAccountConfirm,

    authEpic.resendActivationLink,

    // Api Auth Token Epic
    authEpic.apiTokenAuth,

    // Social Token Auth Epic
    authEpic.socialApiAuth,

    // Password Reset
    authEpic.passwordReset,

    // Password Reset Confirm
    authEpic.passwordResetConfirm,

    // API Auth Token Destroy Epic
    authEpic.apiTokenLogout,

    // Get Download File
    authEpic.getDownloadFile,

    // Update Training Progress
    authEpic.updateTrainingProgress,
    
    // Get User Profile Epic
    authEpic.getUserProfile,

    // Upgrade to Agent Account
    authEpic.upgradeToAgentAccount,

    // Get Agent Profile Epic
    authEpic.getAgentProfile,

    // Get User Orders Epic
    authEpic.getUserOrders,

    // Get User Basket Epic
    authEpic.getUserBasket,

    // Delete Basket Item
    authEpic.deleteBasketItem,

    // Get Shipping Quote Epic
    authEpic.getShippingQuote,

    // Merge Cart
    authEpic.mergeCart,

    // Apply discount code Epic
    authEpic.discountCodeApplication,

    // Validate phone number
    authEpic.validatePhone,

    // Validate verification code
    authEpic.validatePhoneConfirm,

    // Get Vehicle Make Count Epic
    vehicleEpic.getVehicleMakeCount,

    // Search Vehicle Epic
    vehicleEpic.searchVehicle,

    // Search Item
    vehicleEpic.searchItem,

    // Get Products
    vehicleEpic.getProducts,

    // Get All Products
    vehicleEpic.getAllProducts,

    // Get Item
    vehicleEpic.getItem,

    // Get Countries
    vehicleEpic.getCountries,

    // Get Specifications
    vehicleEpic.getSpecifications,

    // Get Price
    vehicleEpic.getPrice,

    // Get Stock Record
    vehicleEpic.getStockRecord,
    
    // Add Product To Basket
    vehicleEpic.addProductToBasket,

    // Place Order
    vehicleEpic.placeOrder,
    
    // // Get Best Deals
    // vehicleEpic.getBestDeals,

    // Get Promotional Api Data
    vehicleEpic.getPromotionalApiData,

    // Get Vehicle Details
    vehicleEpic.getVehicle,

    // Get shipping methods API data
    vehicleEpic.getShippingMethods,

    // Get footer info
    vehicleEpic.getFooterApiData,

    // Get categories info
    vehicleEpic.getCategoriesApiData,
);

export default rootEpic;