import React from "react";
import {Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";


const applyDiscount = price => (price + (0.10 * price)).toFixed(2);

const routeToDetails = (props) => {
  const { history } = props;
  const productId = props.d.id;

  history.push(`/details/${productId}`);
};

const noImage = "https://image.shutterstock.com/image-vector/no-image-available-sign-260nw-1135028591.jpg"

const SearchResGrid = (props) => {

  return<div onClick={() => routeToDetails(props)}
       className="col-lg-3 col-md-3 col-6 mt-4"
       style={{marginBottom: '1.5rem',  cursor: 'pointer'}} key={props.i}>
    <Card>
      <CardImg
        top
        width="100%"
        className="mx-auto car-img img-responsive card-img-top-promo"
        src={ (props.d.primary_image.length !== 0) ? props.d.primary_image[0] : noImage }
        alt="image"
      />
      <CardBody style={{ textAlign: 'left', padding: '16px 0px 8px 2px'}}>
        <div className="car-body-wrapper cards-body">
          <CardSubtitle style={{ paddingBottom: '10px', fontSize: '13px'}}>
            {/*eslint-disable-next-line*/}
            <div className="link-text">
              {props.d.title}
            </div>{' '}
          </CardSubtitle>
          <CardText>
            <section

              style={{float: 'left'}}
            >
              <del style={{display: "None"}}>
                <NumberFormat
                className="home-price"
                value={applyDiscount(props.d.price)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
                />
              </del>
              { '  ' }
              { '  ' }
              <NumberFormat
                className="sale-price"
                value={props.d.price && (props.d.price).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}
              />
            </section>

          </CardText>
        </div>

      </CardBody>
    </Card>
    <hr style={{width: '80%'}}/>
  </div>;

}

export default withRouter(SearchResGrid);