import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import './BestDeals.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import LazyLoad from 'react-lazyload';
import PromoElements from "./PromoElements";
import { prodLandingData } from './../shared/prod_promo_landing_data';
import {Loading} from "./LoadingComponent";


class BestDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      isLoading: true,
      errMess: null,
      dealers: [],
      recommended: [],
      parts: [],
      selectedPriceTab: 1,
      selectedSearchedCarTab: 1
    };
  }

  componentDidMount() {
    this.props.getPromotionalApiData && this.props.getPromotionalApiData();
    this.props.getFooterApiData && this.props.getFooterApiData();
    // this.props.getCategoriesApiData && this.props.getCategoriesApiData();

    if (this.props && this.props.location.state && this.props.location.state.pathname && this.props.location.state.pathname === "/login") {
      document.body.scrollTop = 0
      window.scrollTo(0,0);
    }
  }


  render() {
    let {
      getPromotionalApiDataSuccess,
      getPromotionalApiDataError,
      getPromotionalApiDataLoader,
      getFooterApiDataSuccess,
      getCategoriesApiDataSuccess,
      getCategoriesApiDataLoader,
      getFooterApiDataError,
      getFooterApiDataLoader
    } = this.props;

    let { selectedPriceTab, selectedSearchedCarTab } = this.state;
    let trustedCars = [];
    let mostSearched = [];
    let commercialVehicles = [];
    let drinkAwareApiImg = ''
    if (getFooterApiDataSuccess) {
      drinkAwareApiImg = getFooterApiDataSuccess.results.drink_aware
    }
    let categoriesInfo = []
    if (getCategoriesApiDataSuccess){
      categoriesInfo = getCategoriesApiDataSuccess.data
    }

    if (getPromotionalApiDataSuccess) {
      const {
        trusted_cars,
        most_searched,
        commercial_vehicles,
      } = getPromotionalApiDataSuccess;

      if (selectedPriceTab === 0) {
        trustedCars = trusted_cars.low_range;
      } else if (selectedPriceTab === 1) {
        trustedCars = trusted_cars.mid_range;
      } else if (selectedPriceTab === 2) {
        trustedCars = trusted_cars.upper_range;
      }

      if (selectedSearchedCarTab === 0) {
        mostSearched = most_searched.hatch_back;
      } else if (selectedSearchedCarTab === 1) {
        mostSearched = most_searched.sedan;
      } else if (selectedSearchedCarTab === 2) {
        mostSearched = most_searched.suv;
      }

      commercialVehicles = commercial_vehicles;

    }


      return (
        <div className="dealer">
          {/* Best Deals Start */}
          <div
            className="best-deal"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingTop: '8px',
              paddingBottom: '64px'
            }}
          >
            <div className="col-12"
               style={{
                 paddingBottom: "32px"
               }}
            >
              <div className="headings-div-successful" style={{ textAlign: 'center', display: "None"}}>
                <h2 className="headings">Categories</h2>
              </div>
            </div>
            <div className="container" style={{ paddingTop: '0px'}}>
              {
                getCategoriesApiDataLoader
                ?
                  <div style={{margin: "0 auto"}}>
                    <Loading />
                  </div>
                :
              <div className="row">
                {/*{prodLandingData && prodLandingData.map((d, i) => {*/}
                {/*   return (*/}
                {/*     <LazyLoad height={20} offset={100} once >*/}
                {/*       <PromoElements d={d} i={i}>*/}
                {/*       </PromoElements>*/}
                {/*     </LazyLoad>*/}
                {/*   );                */}
                {categoriesInfo && categoriesInfo.map((d, i) => {
                   return (
                     <LazyLoad height={20} offset={100} once >
                       <PromoElements d={d} i={i}>
                       </PromoElements>
                     </LazyLoad>
                   );
                 })}
              </div>
              }
            </div>
            <hr/>
            <div className="container">
              <div className="row">
                {getFooterApiDataLoader
                  ?
                  <div style={{margin: "0 auto"}}>
                    <Loading />
                  </div>:
                    <img className="card-img-top" style={{maxHeight: "100px"}} src={drinkAwareApiImg} alt="Drink aware" />
                }

              </div>
            </div>
          </div>

        </div>
      );
  }
}

export default withRouter(BestDeals);
