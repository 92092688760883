import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    API_TOKEN_AUTH,
    API_TOKEN_AUTH_SUCCESS,
    API_TOKEN_AUTH_FAILURE,
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    API_TOKEN_DESTROY,
    GET_USER_BASKET,
    GET_USER_BASKET_SUCCESS,
    GET_USER_BASKET_FAILURE,
    GET_USER_ORDERS,
    GET_USER_ORDERS_SUCCESS,
    GET_USER_ORDERS_FAILURE,
    PASSWORD_RESET,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
    PASSWORD_RESET_CONFIRM,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAILURE,
    ACTIVATE_ACCOUNT_CONFIRM,
    ACTIVATE_ACCOUNT_CONFIRM_SUCCESS,
    ACTIVATE_ACCOUNT_CONFIRM_FAILURE,
    API_TOKEN_DESTROY_SUCCESS,
    API_TOKEN_DESTROY_FAILURE,
    RESEND_ACTIVATION_LINK,
    RESEND_ACTIVATION_LINK_SUCCESS,
    RESEND_ACTIVATION_LINK_FAILURE,
    DISCOUNT_CODE_APPLICATION,
    DISCOUNT_CODE_APPLICATION_SUCCESS,
    DISCOUNT_CODE_APPLICATION_FAILURE,
    GET_AGENT_PROFILE,
    GET_AGENT_PROFILE_FAILURE,
    GET_AGENT_PROFILE_SUCCESS,
    UPGRADE_TO_AGENT_ACCOUNT, UPGRADE_TO_AGENT_ACCOUNT_SUCCESS, UPGRADE_TO_AGENT_ACCOUNT_FAILURE,
    GET_SHIPPING_QUOTE,
    GET_SHIPPING_QUOTE_FAILURE, GET_SHIPPING_QUOTE_SUCCESS,
    GET_DOWNLOAD_FILE,
    GET_DOWNLOAD_FILE_SUCCESS,
    GET_DOWNLOAD_FILE_FAILURE, SOCIAL_API_TOKEN,
    UPDATE_TRAINING_PROGRESS,
    UPDATE_TRAINING_PROGRESS_SUCCESS,
    UPDATE_TRAINING_PROGRESS_FAILURE,
    DELETE_BASKET_ITEM,
    DELETE_BASKET_ITEM_SUCCESS,
    DELETE_BASKET_ITEM_FAILURE,
    API_PHONE_VALIDATION, API_PHONE_VALIDATION_SUCCESS, API_PHONE_VALIDATION_FAILURE,
    API_PHONE_VALIDATION_CONFIRM, API_PHONE_VALIDATION_CONFIRM_SUCCESS, API_PHONE_VALIDATION_CONFIRM_FAILURE
} from "../constants";

export class authAction {

    // Register User
    static registerUser(payload) {
        return {
            type: REGISTER_USER,
            payload
        };
    }

    static registerUserSuccess(payload) {
        return {
            type: REGISTER_USER_SUCCESS,
            payload
        };
    }

    static registerUserFailure(error) {
        return {
            type: REGISTER_USER_FAILURE,
            error
        };
    }

    // Basket Management
    static deleteBasketItem(payload) {
        return {
            type: DELETE_BASKET_ITEM,
            payload
        };
    }

    static deleteBasketItemSuccess(payload) {
        return {
            type: DELETE_BASKET_ITEM_SUCCESS,
            payload
        };
    }

    static deleteBasketItemFailure(error) {
        return {
            type: DELETE_BASKET_ITEM_FAILURE,
            error
        };
    }

    // Account Activate Confirm
    static activateAccountConfirm(payload) {
        return {
            type: ACTIVATE_ACCOUNT_CONFIRM,
            payload
        }
    }

    static activateAccountConfirmSuccess(payload) {
        return {
            type: ACTIVATE_ACCOUNT_CONFIRM_SUCCESS,
            payload
        }
    }

    static activateAccountFailure(error) {
        return {
            type: ACTIVATE_ACCOUNT_CONFIRM_FAILURE,
            error
        }
    }

    // Resend Activation Link
    static resendActivationLink(payload) {
        return {
            type: RESEND_ACTIVATION_LINK,
            payload
        }
    }

    static resendActivationLinkSuccess(payload) {
        return {
            type: RESEND_ACTIVATION_LINK_SUCCESS,
            payload
        }
    }

    static resendActivationLinkFailure(error) {
        return {
            type: RESEND_ACTIVATION_LINK_FAILURE,
            error
        }
    }
    // API Social Auth
    static socialApiAuth(payload) {
        return {
            type: SOCIAL_API_TOKEN,
            payload
        }
    }

    // Api Token Auth
    static apiTokenAuth(payload) {
        return {
            type: API_TOKEN_AUTH,
            payload
        };
    }

    static apiTokenAuthSuccess(payload) {
        return {
            type: API_TOKEN_AUTH_SUCCESS,
            payload
        };
    }

    static apiTokenAuthFailure(error) {
        return {
            type: API_TOKEN_AUTH_FAILURE,
            error
        };
    }

    // Api Token Destroy
    static apiTokenLogout(payload) {
        return {
            type: API_TOKEN_DESTROY,
            payload
        }
    }

    static apiTokenLogoutSuccess(payload) {
        return {
            type: API_TOKEN_DESTROY_SUCCESS,
            payload
        };
    }

    static apiTokenLogoutFailure(error) {
        return {
            type: API_TOKEN_DESTROY_FAILURE,
            error
        }
    }

    // Api Reset Password
    static passwordReset(payload) {
        return {
            type: PASSWORD_RESET,
            payload
        }
    }

    static passwordResetSuccess(payload) {
        return {
            type: PASSWORD_RESET_SUCCESS,
            payload
        }
    }

    static passwordResetFailure(error) {
        return {
            type: PASSWORD_RESET_FAILURE,
            error
        }
    }

    // Api Reset Password Confirm
    static passwordResetConfirm(payload) {
        return {
            type: PASSWORD_RESET_CONFIRM,
            payload
        }
    }

    static passwordResetConfirmSuccess(payload) {
        return {
            type: PASSWORD_RESET_CONFIRM_SUCCESS,
            payload
        }
    }

    static passwordResetConfirmFailure(error) {
        return {
            type: PASSWORD_RESET_CONFIRM_FAILURE,
            error
        }
    }

    // Get Download File
    static getDownloadFile(payload) {
        return {
            type: GET_DOWNLOAD_FILE,
            payload
        }
    }

    static getDownloadFileSuccess(payload) {
        return {
            type: GET_DOWNLOAD_FILE_SUCCESS,
            payload
        }
    }

    static getDownloadFileFailure(error) {
        return {
            type: GET_DOWNLOAD_FILE_FAILURE,
            error
        }
    }

    // Update Training Progress
    static updateTrainingProgress(payload) {
        return {
            type: UPDATE_TRAINING_PROGRESS,
            payload
        }
    }

    static updateTrainingProgressSuccess(payload) {
        return {
            type: UPDATE_TRAINING_PROGRESS_SUCCESS,
            payload
        }
    }

    static updateTrainingProgressFailure(error) {
        return {
            type: UPDATE_TRAINING_PROGRESS_FAILURE,
            error
        }
    }

    // Get User Profile
    static getUserProfile(payload) {
        return {
            type: GET_USER_PROFILE,
            payload
        };
    }

    static getUserProfileSuccess(payload) {
        return {
            type: GET_USER_PROFILE_SUCCESS,
            payload
        };
    }

    static getUserProfileFailure(error) {
        return {
            type: GET_USER_PROFILE_FAILURE,
            error
        };
    }

    // Get Agent Profile
    static getAgentProfile(payload) {
        return {
            type: GET_AGENT_PROFILE,
            payload
        };
    }

    static getAgentProfileSuccess(payload) {
        return {
            type: GET_AGENT_PROFILE_SUCCESS,
            payload
        };
    }

    static getAgentProfileFailure(error) {
        return {
            type: GET_AGENT_PROFILE_FAILURE,
            error
        };
    }

    // Create Agent Account
    static upgradeToAgentAccount(payload) {
        return {
            type: UPGRADE_TO_AGENT_ACCOUNT,
            payload
        };
    }

    static upgradeToAgentAccountSuccess(payload) {
        return {
            type: UPGRADE_TO_AGENT_ACCOUNT_SUCCESS,
            payload
        };
    }

    static upgradeToAgentAccountFailure(error){
        return {
            type: UPGRADE_TO_AGENT_ACCOUNT_FAILURE,
            error
        }
    }

    // Get User Orders
    static getUserOrders(payload) {
        return {
            type: GET_USER_ORDERS,
            payload
        };
    }

    static getUserOrdersSuccess(payload) {
        return {
            type: GET_USER_ORDERS_SUCCESS,
            payload
        };
    }

    static getUserOrdersFailure(error) {
        return {
            type: GET_USER_ORDERS_FAILURE,
            error
        };
    }

    // Get User Basket
    static getUserBasket(payload) {
        return {
            type: GET_USER_BASKET,
            payload
        };
    }

    static getUserBasketSuccess(payload) {
        return {
            type: GET_USER_BASKET_SUCCESS,
            payload
        };
    }

    static  getUserBasketFailure(error) {
        return {
            type: GET_USER_BASKET_FAILURE,
            error
        }
    }
    // Get shipping quote
    static getShippingQuote(payload) {
        return {
            type: GET_SHIPPING_QUOTE,
            payload
        };
    }

    static getShippingQuoteSuccess(payload) {
        return {
            type: GET_SHIPPING_QUOTE_SUCCESS,
            payload
        };
    }

    static getShippingQuoteFailure(error) {
        return {
            type: GET_SHIPPING_QUOTE_FAILURE,
            error
        };
    }

    // Apply discount code
    static discountCodeApplication(payload) {
        return {
            type: DISCOUNT_CODE_APPLICATION,
            payload
        };
    }

    static discountCodeApplicationSuccess(payload) {
        return {
            type: DISCOUNT_CODE_APPLICATION_SUCCESS,
            payload
        };
    }

    static discountCodeApplicationFailure(error) {
        return {
            type: DISCOUNT_CODE_APPLICATION_FAILURE,
            error
        };
    }

    // Validate user phone number
    static validatePhone(payload) {
        return {
            type: API_PHONE_VALIDATION,
            payload
        };
    }

    static validatePhoneSuccess(payload) {
        return {
            type: API_PHONE_VALIDATION_SUCCESS,
            payload
        };
    }

    static validatePhoneFailure(error) {
        return {
            type: API_PHONE_VALIDATION_FAILURE,
            error
        };
    }

    // Validate user phone number verification code
    static validatePhoneConfirm(payload) {
        return {
            type: API_PHONE_VALIDATION_CONFIRM,
            payload
        };
    }

    static validatePhoneConfirmSuccess(payload) {
        return {
            type: API_PHONE_VALIDATION_CONFIRM_SUCCESS,
            payload
        };
    }

    static validatePhoneConfirmFailure(error) {
        return {
            type: API_PHONE_VALIDATION_CONFIRM_FAILURE,
            error
        };
    }
}
