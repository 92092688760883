import React, { Component } from "react";
import ImgAndForm from "./ImgAndForm";
import ShopByMake from "./../containers/ShopByMakeContainer";
import BestDeals from "./../containers/BestDealsContainer";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import cmcmtLogo from "../assets/images/cmct/excel-academy-logos.png";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShopByMake,
      search: {},
      ageVerificationModal: false
    };

    this.onSearch = this.onSearch.bind(this);
    this.toggleAgeModal = this.toggleAgeModal.bind(this);
  }

  componentDidMount() {
    const ageVerified = localStorage.getItem("ageVerified")
    if (ageVerified === "true") {
    } else {
      this.setState({
        ageVerificationModal: true
      })
    }
  }

  onSearch(search) {
    this.props.history.push(`${search}`);
  }

  toggleAgeModal = () => {
    localStorage.setItem("ageVerified", "true")
    this.setState({
      ageVerificationModal: false
    });
  }


  render() {
    return (
      <div style={{ backgroundColor: "grey" }}>
        {/* <ImgAndForm onSearch={this.onSearch} /> */}
        <section className="gen-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <Modal isOpen={false} toggle={() => this.toggleAgeModal(false)}>
                  <ModalHeader
                    style={{fontWeight: "bold", textAlign: "center"}}
                    toggle={() => this.toggleAgeModal(false)}>
                      <p style={{fontWeight: "bold"}}>
                        You must be over the age of 18 to enter this site
                      </p>

                  </ModalHeader>
                  <ModalBody style={{ textAlign: "center"}}>
                    <div>
                      <img style={{ padding: "0 10px", height: "100px"}}
                           src={cmcmtLogo} alt="" />
                    </div>
                    <div style={{padding: "32px 0px 16px 0px", fontWeight: "bold"}} >
                      Are you over 18 years of age?
                    </div>
                    <div>
                      <Button style={{ marginRight: "10px"}}
                              color="primary"
                              onClick={() => this.toggleAgeModal(false)}>
                        Yes
                      </Button>
                      <Button color="primary">No</Button>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <div style={{textAlign: "center", fontSize: "80%"}}>
                      <p>
                        We run a challenge 25 policy. Be prepared to show your ID on delivery!
                      </p>
                      <p>
                        This site uses cookies.
                        For more information on how we use personal information see our privacy policy
                      </p>
                    </div>
                  </ModalFooter>
                </Modal>

                <ImgAndForm onSearch={this.onSearch} />

                <BestDeals />

              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
