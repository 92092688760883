import {
  GET_VEHICLE_MAKE_COUNT,
  GET_VEHICLE_MAKE_COUNT_SUCCESS,
  GET_VEHICLE_MAKE_COUNT_FAILURE,
  SEARCH_VEHICLE,
  SEARCH_VEHICLE_SUCCESS,
  SEARCH_VEHICLE_FAILURE,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_SPECIFICATIONS,
  GET_SPECIFICATIONS_SUCCESS,
  GET_SPECIFICATIONS_FAILURE,
  GET_PRICE,
  GET_PRICE_SUCCESS,
  GET_PRICE_FAILURE,
  ADD_PRODUCT_TO_BASKET,
  ADD_PRODUCT_TO_BASKET_SUCCESS,
  ADD_PRODUCT_TO_BASKET_FAILURE,
  PLACE_ORDER,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  GET_PROMOTIONAL_API_DATA,
  GET_PROMOTIONAL_API_DATA_SUCCESS,
  GET_PROMOTIONAL_API_DATA_FAILURE,
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAILED,
  GET_STOCK_RECORD,
  GET_STOCK_RECORD_SUCCESS,
  GET_STOCK_RECORD_FAILURE,
  GET_SHIPPING_METHODS, GET_SHIPPING_METHODS_SUCCESS, GET_SHIPPING_METHODS_FAILURE,
  SEARCH_ITEM, SEARCH_ITEM_SUCCESS, SEARCH_ITEM_FAILURE,
  GET_ITEM, GET_ITEM_SUCCESS, GET_ITEM_FAILED, GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE, GET_FOOTER_API_DATA, GET_FOOTER_API_DATA_SUCCESS, GET_FOOTER_API_DATA_FAILURE,
  GET_CATEGORIES_API_DATA, GET_CATEGORIES_API_DATA_SUCCESS, GET_CATEGORIES_API_DATA_FAILURE
} from "../constants";

const initialState = {
  vehicleMakeCountSuccess: null,
  vehicleMakeCountError: null,
  vehicleMakeCountLoader: false,

  searchVehicleSuccess: null,
  searchVehicleError: null,
  searchVehicleLoader: false,

  searchItemSuccess: null,
  searchItemError: null,
  searchItemLoader: false,

  getProductsSuccess: null,
  getProductsError: null,
  getProductsLoader: false,

  getAllProductsSuccess: null,
  getAllProductsError: null,
  getAllProductsLoader: false,

  getCountriesSuccess: null,
  getCountriesError: null,
  getCountriesLoader: false,

  getSpecificationsSuccess: null,
  getSpecificationsError: null,
  getSpecificationsLoader: false,
  
  getPriceSuccess: null,
  getPriceError: null,

  getStockRecordSuccess: null,
  getStockRecordError: null,
  
  addProductToBasketSuccess: null,
  addProductToBasketError: null,
  addProductToBasketLoader: false,

  placeOrderSuccess: null,
  placeOrderError: null,
  placeOrderLoader: false,
  //
  // getBestDealsSuccess: null,
  // getBestDealsError: null,
  // getBestDealsLoader: false,
  
  getPromotionalApiDataSuccess: null,
  getPromotionalApiDataError: null,
  getPromotionalApiDataLoader: false,

  getFooterApiDataSuccess: null,
  getFooterApiDataError: null,
  getFooterApiDataLoader: false,

  getCategoriesApiDataSuccess: null,
  getCategoriesApiDataError: null,
  getCategoriesApiDataLoader: false,
  
  getVehicleDetail: null,
  getVehicleDetailError: null,
  getVehicleDetailLoader: false,

  getItemDetail: null,
  getItemDetailError: null,
  getItemDetailLoader: false,

  getShippingMethodsSuccess: null,
  getShippingMethodsError: null,
  getShippingMethodsLoader: false

};

export default function vehicleReducer(state = initialState, action) {
  switch (action.type) {
    // Vehicle Make Count
    case GET_VEHICLE_MAKE_COUNT:
      return {
        ...state,
        vehicleMakeCountLoader: true,
        vehicleMakeCountSuccess: null,
        vehicleMakeCountError: null
      };

    case GET_VEHICLE_MAKE_COUNT_SUCCESS:
      return {
        ...state,
        vehicleMakeCountLoader: false,
        vehicleMakeCountSuccess: action.payload,
        vehicleMakeCountError: null
      };

    case GET_VEHICLE_MAKE_COUNT_FAILURE:
      return {
        ...state,
        vehicleMakeCountLoader: false,
        vehicleMakeCountSuccess: null,
        vehicleMakeCountError: action.error
      };

    // Search Vehicle
    case SEARCH_VEHICLE:
      return {
        ...state,
        searchVehicleLoader: true,
        searchVehicleSuccess: null,
        searchVehicleError: null
      };

    case SEARCH_VEHICLE_SUCCESS:
      return {
        ...state,
        searchVehicleLoader: false,
        searchVehicleSuccess: action.payload,
        searchVehicleError: null
      };

    case SEARCH_VEHICLE_FAILURE:
      return {
        ...state,
        searchVehicleLoader: false,
        searchVehicleSuccess: null,
        searchVehicleError: action.error
      };

    // Search Item
    case SEARCH_ITEM:
      return {
        ...state,
        searchItemLoader: true,
        searchItemSuccess: null,
        searchItemError: null
      };

    case SEARCH_ITEM_SUCCESS:
      return {
        ...state,
        searchItemLoader: false,
        searchItemSuccess: action.payload,
        searchItemError: null
      };

    case SEARCH_ITEM_FAILURE:
      return {
        ...state,
        searchItemLoader: false,
        searchItemSuccess: null,
        searchItemError: action.error
      };

    // Get Products
    case GET_PRODUCTS:
      return {
        ...state,
        getProductsLoader: true,
        getProductsSuccess: null,
        getProductsError: null
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        getProductsSuccess: action.payload,
        getProductsError: null
      };

    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        getProductsLoader: false,
        getProductsSuccess: null,
        getProductsError: action.error
      };

    // Get All Products
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        getAllProductsLoader: true,
        getAllProductsSuccess: null,
        getAllProductsError: null
      };

    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        getAllProductsLoader: false,
        getAllProductsSuccess: action.payload,
        getProductsError: null
      };

    case GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        getAllProductsLoader: false,
        getAllProductsSuccess: null,
        getAllProductsError: action.error
      };

    // Get Countries
    case GET_COUNTRIES:
      return {
        ...state,
        getCountriesSuccess: null,
        getCountriesError: null
      };

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        getCountriesSuccess: action.payload,
        getCountriesError: null
      };

    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        getCountriesSuccess: null,
        getCountriesError: action.error
      };

    // Get Specifications
    case GET_SPECIFICATIONS:
      return {
        ...state,
        getSpecificationsSuccess: null,
        getSpecificationsError: null
      };

    case GET_SPECIFICATIONS_SUCCESS:
      return {
        ...state,
        getSpecificationsSuccess: action.payload,
        getSpecificationsError: null
      };

    case GET_SPECIFICATIONS_FAILURE:
      return {
        ...state,
        getSpecificationsSuccess: null,
        getSpecificationsError: action.error
      };

    // Get Price
    case GET_PRICE:
      return {
        ...state,
        getPriceSuccess: null,
        getPriceError: null
      };

    case GET_PRICE_SUCCESS:
      return {
        ...state,
        getProductsLoader: false,
        getPriceSuccess: action.payload,
        getPriceError: null
      };

    case GET_PRICE_FAILURE:
      return {
        ...state,
        getProductsLoader: false,
        getPriceSuccess: null,
        getPriceError: action.error
      };

    // Get Stock Record
    case GET_STOCK_RECORD:
      return {
        ...state,
        getStockRecordSuccess: null,
        getStockRecordError: null
      };

    case GET_STOCK_RECORD_SUCCESS:
      return {
        ...state,
        getProductsLoader: false,
        getStockRecordSuccess: action.payload,
        getStockRecordError: null
      }

    case GET_STOCK_RECORD_FAILURE:
      return {
        ...state,
        getProductsLoader: false,
        getStockRecordSuccess: null,
        getStockRecordError: action.error
      }


    // Add Product To Basket
    case ADD_PRODUCT_TO_BASKET:
      return {
        ...state,
        addProductToBasketLoader: true,
        addProductToBasketSuccess: null,
        addProductToBasketError: null
      };

    case ADD_PRODUCT_TO_BASKET_SUCCESS:
      return {
        ...state,
        addProductToBasketLoader: false,
        addProductToBasketSuccess: action.payload,
        addProductToBasketError: null
      };

    case ADD_PRODUCT_TO_BASKET_FAILURE:
      return {
        ...state,
        addProductToBasketLoader: false,
        addProductToBasketSuccess: null,
        addProductToBasketError: action.error
      };

    case PLACE_ORDER:
      return {
        ...state,
        placeOrderLoader: true,
        placeOrderSuccess: null,
        placeOrderError: null
      };

    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        placeOrderLoader: false,
        placeOrderSuccess: action.payload,
        placeOrderError: null
      };

    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        placeOrderLoader: false,
        placeOrderSuccess: null,
        placeOrderError: action.error
      }

    // Get Best Deals
    // case GET_BEST_DEALS:
    //   return {
    //     ...state,
    //     getBestDealsLoader: true,
    //     getBestDealsSuccess: null,
    //     getBestDealsError: null
    //   };
    //
    // case GET_BEST_DEALS_SUCCESS:
    //   return {
    //     ...state,
    //     getBestDealsLoader: false,
    //     getBestDealsSuccess: action.payload,
    //     getBestDealsError: null
    //   };
    //
    // case GET_BEST_DEALS_FAILURE:
    //   return {
    //     ...state,
    //     getBestDealsLoader: false,
    //     getBestDealsSuccess: null,
    //     getBestDealsError: action.error
    //   };

    // Get Promotional Api Data
    case GET_PROMOTIONAL_API_DATA:
      return {
        ...state,
        getPromotionalApiDataLoader: true,
        getPromotionalApiDataSuccess: null,
        getPromotionalApiDataError: null
      };

    case GET_PROMOTIONAL_API_DATA_SUCCESS:
      return {
        ...state,
        getPromotionalApiDataLoader: false,
        getPromotionalApiDataSuccess: action.payload,
        getPromotionalApiDataError: null
      };

    case GET_PROMOTIONAL_API_DATA_FAILURE:
      return {
        ...state,
        getPromotionalApiDataLoader: false,
        getPromotionalApiDataSuccess: null,
        getPromotionalApiDataError: action.error
      };

    case GET_FOOTER_API_DATA:
      return {
        ...state,
        getFooterApiDataLoader: true,
        getFooterApiDataSuccess: null,
        getFooterApiDataError: null
      }

    case GET_FOOTER_API_DATA_SUCCESS:
      return {
        ...state,
        getFooterApiDataLoader: false,
        getFooterApiDataSuccess: action.payload,
        getFooterApiDataError: null
      }

    case GET_FOOTER_API_DATA_FAILURE:
      return {
        ...state,
        getFooterApiDataLoader: false,
        getFooterApiDataSuccess: null,
        getFooterApiDataError: action.error
      }    
      
    case GET_CATEGORIES_API_DATA:
      return {
        ...state,
        getCategoriesApiDataLoader: true,
        getCategoriesApiDataSuccess: null,
        getCategoriesApiDataError: null
      }

    case GET_CATEGORIES_API_DATA_SUCCESS:
      return {
        ...state,
        getCategoriesApiDataLoader: false,
        getCategoriesApiDataSuccess: action.payload,
        getCategoriesApiDataError: null
      }

    case GET_CATEGORIES_API_DATA_FAILURE:
      return {
        ...state,
        getCategoriesApiDataLoader: false,
        getCategoriesApiDataSuccess: null,
        getCategoriesApiDataError: action.error
      }

    case GET_SHIPPING_METHODS:
      return {
        ...state,
        getShippingMethodsLoader: true,
        getShippingMethodsSuccess: null,
        getShippingMethodsError: null,
      };

    case GET_SHIPPING_METHODS_SUCCESS:
      return {
        ...state,
        getShippingMethodsLoader: false,
        getShippingMethodsSuccess: action.payload,
        getShippingMethodsError: null
      }

    case GET_SHIPPING_METHODS_FAILURE:
      return {
        ...state,
        getShippingMethodsLoader: false,
        getShippingMethodsSuccess: null,
        getShippingMethodsError: action.error
      }

    case GET_VEHICLE: {
      return {
        ...state,
        getVehicleDetail: null,
        getVehicleDetailError: null,
        getVehicleDetailLoader: true
      }
    }

    case GET_VEHICLE_SUCCESS: {
      return {
        ...state,
        getVehicleDetail: action.payload,
        getVehicleDetailError: null,
        getVehicleDetailLoader: false
      }
    }

    case GET_VEHICLE_FAILED: {
      return {
        ...state,
        getVehicleDetail: null,
        getVehicleDetailError: true,
        getVehicleDetailLoader: false
      }
    }

    case GET_ITEM: {
      return {
        ...state,
        getItemDetail: null,
        getItemDetailError: null,
        getItemDetailLoader: true
      }
    }

    case GET_ITEM_SUCCESS: {
      return {
        ...state,
        getItemDetail: action.payload,
        getItemDetailError: null,
        getItemDetailLoader: false
      }
    }

    case GET_ITEM_FAILED: {
      return {
        ...state,
        getItemDetail: null,
        getItemDetailError:action.error,
        getItemDetailLoader: false
      }
    }

    default:
      return state;
  }
}
