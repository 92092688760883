  import {
  ADD_TO_CART,
  DELETE_CART_ITEM,
  MERGE_CART,
  MERGE_CART_SUCCESS,
  MERGE_CART_FAILURE,
  CLEAR_CART
} from "../constants"

const initialState = {
  cartProducts: [],
  cartProductsReduced: [],

  mergeCartSuccess: null,
  mergeCartError: null,
  mergeCartLoader: false,
}

export default function cartReducer(state= initialState, action) {
  switch (action.type) {

    case ADD_TO_CART:
      let reducedCart = []
      let numItem = action.payload.numItems
      let added = Array.from(
          { length: numItem },
          () => (action.payload)
      );
      let combined = state.cartProducts.concat(added)
      if (combined.length > 0) {
        reducedCart = Object.values(combined.reduce((a, b) => {
          a[b.prodId + b.prodTitle] = Object
              .assign(b, {
                count: (
                    a[b.prodId + b.prodTitle] || {count: 0}).count + 1}
              );
          return a;
        }, {}));
      }
      return {
        ...state,
        cartProducts: combined,
        cartProductsReduced: reducedCart
      }


    case DELETE_CART_ITEM:
      let result = state.cartProducts.filter(function(v, i) {
        return ((v["prodId"] !== action.payload.prodId));
      })
      let reducedResult = state.cartProductsReduced.filter(function(v, i) {
        return ((v["prodId"] !== action.payload.prodId));
      })
      return {
        ...state,
        cartProducts: result,
        cartProductsReduced: reducedResult
      }

    case CLEAR_CART:
      return {
        ...state,
        cartProducts: [],
        cartProductsReduced: []
    }

    case MERGE_CART:
      return {
        ...state,
        mergeCartLoader: true,
        mergeCartSuccess: null,
        mergeCartError: null
      };

    case MERGE_CART_SUCCESS:
      return {
        ...state,
        mergeCartLoader: false,
        mergeCartSuccess: action.payload,
        mergeCartError: null
      }

    case MERGE_CART_FAILURE:
      return {
        ...state,
        mergeCartLoader: false,
        mergeCartSuccess: null,
        mergeCartError: action.error
      }


    default:
      return state
  }
}
