import React, {Component} from 'react';
import {withCookies} from "react-cookie";
import {Alert, Button, FormGroup, Input, Row} from "reactstrap";
import NumberFormat from "react-number-format";
import {Loading} from "../LoadingComponent";
import credentials from "../../config/credentials";


class UserBasket extends Component {

  constructor(props) {
    super(props);

    this.removeItemFromBasket = this.removeItemFromBasket.bind(this);
    this.proceedToCheckout = this.proceedToCheckout.bind(this);
    this.handleShippingQuoteReq = this.handleShippingQuoteReq.bind(this);
    this.applyDiscountCode = this.applyDiscountCode.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.updateState = this.updateState.bind(this)

    this.state = {
      count: 0,
      results: [],
      shippingChargeOption: {},
      status: "",
      total: "",
      image: "",
      destinationPort: "",
      destinationCode: "",
      discountCode: "",
      error: false,
      message: "",
      isButtonDisabled: false,
      userSelectedDestination: "",
      basketID: "",
      loader: false
    }
  }

  async componentDidMount() {
    const { history, location, isLoggedIn } = this.props
    const token = localStorage.getItem('token')
    if (!isLoggedIn) {
      history.push("/login", location)
    }

    let headers = { Authorization: `token ${token}` };

    this.props.getUserBasket && this.props.getUserBasket(headers)
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.getUserBasketSuccess &&
      prevProps.getUserBasketSuccess !== this.props.getUserBasketSuccess) {
      this.setState({ ...this.props.getUserBasketSuccess})
    }
    if (this.props.getShippingQuoteSuccess &&
      prevProps.getShippingQuoteSuccess !== this.props.getShippingQuoteSuccess) {
      window.location.reload();
    }
    if (this.props.deleteBasketItemSuccess &&
      prevProps.deleteBasketItemSuccess !== this.props.deleteBasketItemSuccess) {
      const { history, location, isLoggedIn } = this.props
      const token = localStorage.getItem('token')
      if (!isLoggedIn) {
        history.push("/login", location)
      }

      let headers = { Authorization: `token ${token}` };

      this.props.getUserBasket && this.props.getUserBasket(headers)
    }
    if (this.props.getUserBasketError === "AjaxError: ajax error 401") {
      const { history, location } = this.props;
      history.push("/login", location);
    }
    if (this.props.applyDiscountCodeError &&
      prevProps.applyDiscountCodeError !== this.props.applyDiscountCodeError) {
      this.setState({
        error: true,
        message: "Discount code you entered is invalid or not applicable to the contents of your basket"
      })
    }
    if (this.props.applyDiscountCodeSuccess &&
      prevProps.applyDiscountCodeSuccess !== this.props.applyDiscountCodeSuccess) {
      const token = localStorage.getItem('token')
      let headers = { Authorization: `token ${token}` };
      this.setState({error: false, message: "Discount code applied."})
      this.props.getUserBasket && this.props.getUserBasket(headers)
    }
  }

  handleChange(event) {
      this.setState({value: event.target.value});
      const target = event.target;
      const name = target.name;
      this.setState({
          [name]: target.value
      })
  }

  updateState(destinationName, destinationCode, calculatedShippingOption, totalCost, userSelectedDestination){
    this.setState({
    destinationPort: destinationName,
    destinationCode: destinationCode,
    shippingChargeOption: calculatedShippingOption,
    totalCost: totalCost,
    userSelectedDestination: userSelectedDestination
    })
  }


  renderBasketData(banana, loader){
    return banana.map(
      ({ productTitle, price, quantity, url, productImage, size, totalCost, destinationPort,
         hasDiscount, discountValue}, i) => {
        return (
          <div>
          <div className="row">
            <div className="col-3 col-3-custom">
              <span className="helper"></span><img src={productImage} alt="cmct-image" className="img-basket"/>
            </div>
            <div className="col-6 col-3-custom basket-page-information ">
              <p className="basket-item-title">{productTitle}</p>
              {size ?
                <p>Size: {size}</p>
                :
                null
              }
              Qty: {quantity}
              <br>
              </br>
              {/*eslint-disable-next-line*/}
              <a className="a-links" href="#" onClick={() => this.removeItemFromBasket(url)}>
                - Remove
              </a>
            </div>
            <div className="col-3 col-3-custom basket-page-information">
              <NumberFormat
                value={price} displayType={'text'}
                thousandSeparator={true}
                prefix={'£'}/>
            </div>
          </div>
          {hasDiscount ?
          <div>
            <div className="row">
              <div className="col-3 col-3-custom">
              </div>
              <div className="col-6 col-3-custom basket-page-information">
                <p className="basket-item-title" style={{color: '#3e5067'}}>Discounts & Savings </p>
              </div>
              <div className="col-3 col-3-custom basket-page-information" style={{color: '#3e5067'}}>
                - <NumberFormat className="col-3-custom basket-page-information" style={{color: '#3e5067'}}
                                value={discountValue} displayType={'text'}
                                thousandSeparator={true}
                                prefix={'£'}/>
              </div>
            </div>
          </div>
          :
            null
          }
          <hr className="thicker-hr" width="80%"/>
          <div className="bottom-out-space">

          </div>
          </div>
        );
      }
    )
  }

  // TODO Align this implementation
  removeItemFromBasket(url){
    const payload = {url: url}
    this.props.deleteBasketItem && this.props.deleteBasketItem(payload)
  }

  handleShippingQuoteReq(event, url, lineAttribute) {
    let basketData = JSON.stringify(lineAttribute)
    this.sendRequest(
      process.env.REACT_APP_EMAILJS_TEMPLATEID, "notapplicable", "noapplicate@gmail.com",
      process.env.REACT_APP_EMAILJS_RECEIVER, "Shipping quotation request: " + url + " - " + basketData ,
      "070000000")

    alert('A shipping quote request was submitted, Basket will be updated within 20 minutes')
    event.preventDefault()
    const { basketID } = this.state
    let payload = { basketID: basketID}
    this.props.getShippingQuote( payload )
  }

  sendRequest(templateId, userName, userEmail, receiverEmail, userRequest, contactNumber) {
    window.emailjs.send(
      'sendgrid',
      templateId,
        {
          userName,
          userEmail,
          receiverEmail,
          contactNumber,
          userRequest
        })
        .catch(err => console.error('Failed to send feedback. Error: ', err))
  }

  proceedToCheckout (event, shippingCost) {
    if (parseInt(this.state.shippingChargeOption.value) < 1){
      alert('Unable to proceed. Shipping cost has not been determined. ' +
        'Click the link in your basket to request for a quote')
      return
    }
    const {history} = this.props
    history.push({
      pathname: "/checkout",
      state: this.state
    })
  }

  proceedToHome () {
    const {history} = this.props
    history.push({
      pathname: "/",
    })
  }

  applyDiscountCode () {

     const { discountCode, results } = this.state

    if (results[0].has_discount) {
      this.setState({error: true, message: "Discount already applied to your basket"})
      return;
    }

    if (discountCode === "") {
      // this.electronicsRequestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      this.setState({error: true, message: "Error - Discount Code Required"})
      return;
    }
    if (discountCode.length < 5) {
      this.setState({error: true, message: "Error - Code length must be minimum 5 character"})
    }

    this.props.discountCodeApplication && this.props.discountCodeApplication({vouchercode: discountCode});
  }

  render() {
    const {count, results, message, error, is_shipping_requested, totalCost, deliveryCharge, total } = this.state;
    const totalIncDelivery = deliveryCharge && deliveryCharge.incl_tax + parseFloat(total)
    const { getUserBasketLoader,  applyDiscountCodeLoader } = this.props


    if (results.length !== 0 ) {
      let userSelectedDestination = "FREE SHIPPING"
      let calulatedShippingOption = "Zim"
      let destinationName = "FREE"
      let destinationCode = "Zim"
      let totalCost = results.map(x => parseFloat(x.price_incl_tax))
      totalCost = reduceTotalCost(totalCost)
      if (this.state.destinationPort === "") {
        this.updateState(destinationName, destinationCode, calulatedShippingOption, totalCost, userSelectedDestination)
        }
    }

    function reduceTotalCost (arr) {
      let total = arr.reduce((a, b) => a + b, 0)
      return total
    }

    function getShippingOption(d){
      let res = Math.max.apply(Math, d.map(function (o) {return o.value;}))
      let maxShipping = d.find(function (o){return o.value == res})
      return maxShipping
    }

    function getBasketInfo(item) {
      let url = item.url
      let lineAtt = "null"
      let sizeOption = null
      let imageUrl = item.primary_image
      if (item.attributes && item.attributes.length) {
        sizeOption = item.attributes[0].value
      }
      if (process.env.REACT_APP_API_ENDPOINT === 'prod') {
        // url = item.url.replace('http', 'https')
        // lineAtt = item.attributes[0].url.replace('http', 'https')
        imageUrl = imageUrl.replace("http://localhost:8263", credentials.BASE_URL)
      }
      let shippingCost = 0

      return {
        "productTitle": item.title, "price": item.price_incl_tax_excl_discounts,
        "quantity": item.quantity, "url": url,
        "productImage": imageUrl, "shippingCost": 0,
        "totalCost": parseInt(item.price_incl_tax) + parseInt(shippingCost),
        "lineAttribute": lineAtt, "hasDiscount": item.has_discount,
        "discountValue": parseInt(item.price_incl_tax_excl_discounts) - parseInt(item.price_incl_tax),
        "size": sizeOption
        }
    }
    const mappedData = results.map(getBasketInfo)
    const urls = mappedData.map(x => x.url)
    let shippingRequestBtn = "Click here to request a quote"
    let shippingRequestMsg = "Please Note: Shipping cost not determined"

    if (is_shipping_requested) {
      shippingRequestBtn = "Request is Pending"
      shippingRequestMsg = "Shipping cost request submitted"
    }

    let backgoundColor = "yellow"

    const { deleteBasketItemSuccess, deleteBasketItemError, deleteBasketLoader } = this.props


    const itemInBasket = results.length > 0
    return(
      <div>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page">
                  <div className="" style={{ textAlign: 'center' }}>

                    <h4 className="page-header-content">My Bag</h4>
                    <hr className="fancy-line" style={{ marginBottom: '32px' }}/>
                      {getUserBasketLoader || applyDiscountCodeLoader || deleteBasketLoader ? (
                        <div className="">
                          <Loading />
                        </div>
                      ) : (
                      <div>
                        <div className="row basket-checkout-row">
                          <div className="col-sm-6 basket-page-information">
                            Your bag total is {' '}
                            <NumberFormat className="col-3-custom basket-page-information"
                                          value={totalCost && (totalIncDelivery).toFixed(2)}
                                          displayType={'text'}
                                          thousandSeparator={true} prefix={'£'}/>
                          </div>
                          { itemInBasket ? (
                            <div className="col-sm-6" style={{overflow: 'auto'}}>
                              <Button className="delete-basket-button"
                                      size="sm"
                                      onClick={(event) => this.proceedToCheckout(event, mappedData[0].shippingCost)}>
                                Proceed to Checkout
                              </Button>
                            </div>
                          ): (
                            <div className="col-sm-6" style={{overflow: 'auto'}}>
                              <Button className="delete-basket-button"
                                      size="sm" onClick={() => this.proceedToHome()}>Continue Shopping
                              </Button>
                            </div>
                          )}
                        </div>
                        {itemInBasket ? (
                        <div className="container">
                          <div className="row basket-header">
                            <div className="col-9 col-3-custom basket-page-information basket-page-information-header">
                             PRODUCT
                            </div>
                            <div className="col-3 col-3-custom basket-page-information basket-page-information-header">
                              PRICE
                            </div>
                          </div>
                          {this.renderBasketData(mappedData, deleteBasketLoader)}
                          <div className="row">
                            <div className="col-3 col-3-custom">
                              <input type="radio" name="radio-group" value="1" defaultChecked />
                            </div>
                            <div className="col-6 col-3-custom basket-page-information">
                              {/*<p className="basket-item-title">{deliveryCharge && deliveryCharge.incl_tax} </p>*/}
                              <p className="basket-item-title" style={{color: 'red'}}>
                                <div style={{color: '#aaa'}}> Delivery Charge </div>
                              </p>
                            </div>
                            <div className="col-3">
                              <NumberFormat className="col-3-custom basket-page-information"
                                            value={deliveryCharge && (deliveryCharge.incl_tax).toFixed(2)}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'£'}/>
                            </div>
                            <hr className="thicker-hr" width="80%"/>
                          </div>
                          <div className="row">
                            <div className="col-3 col-3-custom">
                            </div>
                            <div className="col-6 col-3-custom basket-page-information ">
                              <p style={{fontWeight: 700}} className="basket-item-title">Total</p>
                            </div>
                            <div className="col-3 col-3-custom" >
                              <NumberFormat className="basket-page-information"
                                            value={totalCost && (totalIncDelivery).toFixed(2)}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'£'}/>
                            </div>
                          </div>
                          <div style={{paddingBottom: '20px'}}>
                            <hr className="thicker-hr" width="80%"/>
                          </div>
                        </div>): (
                          <div className="page-information"> You have no items in your basket. <p>
                            </p>
                          </div>
                        )}
                        { itemInBasket ? (
                        <div>
                          <Row>
                            <div className="col-12">
                              <div ref={this.electronicsRequestRef}>
                                {message &&
                                <Alert color={error ? "danger" : "success"} style={{display: 'inline-block'}}>
                                  {message}
                                </Alert>}
                              </div>
                            </div>
                          </Row>
                          <div className="row basket-checkout-row">
                            <div className="col-sm-6 basket-page-information"
                                 style={{paddingLeft: '20px', paddingRight: '20px'}}>
                              <FormGroup style={{display: 'inline-block'}}>
                                <Input
                                  onChange={this.handleChange}
                                  type="text"
                                  name="discountCode"
                                  id="discount-code-element"
                                  placeholder="Enter Discount Code here"
                                  value={this.state.discountCode}
                                  maxLength={255}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-sm-6" style={{overflow: 'auto'}}>
                              <Button className="delete-basket-button"
                                      size="sm"
                                      onClick={() => this.applyDiscountCode()}>
                                Apply Discount Code
                              </Button>
                            </div>
                          </div>
                        </div>
                        ): (
                        null
                      )}
                      </div>)}
                  </div>
                </div>
              </div>
            </div>
           <div className="row help-row">
             <div className="col-lg-12">
             </div>
           </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withCookies(UserBasket)