console.log(process.env.REACT_APP_API_ENDPOINT)

console.log("=====================================================")
let credentials = {
  API_URL: "http://localhost/api",
  BASE_URL: "http://localhost:8024",
  PROMOTIONAL_API_URL: "http://localhost/api/promotional/",
  PRODUCT_URL: "http://localhost/products",
  PRODUCT_DETAILS_URL: "http://localhost/api/products"
};

if(process.env.REACT_APP_API_ENDPOINT==="staging"){
  credentials = {
    API_URL: "https://doorstep-booze-stage.beeframeworks.co.uk/api",
    BASE_URL: "https://doorstep-booze-stage.beeframeworks.co.uk",
    PROMOTIONAL_API_URL: "https://doorstep-booze-stage.beeframeworks.co.uk/api/promotional/",
    PRODUCT_URL: "https://doorstep-booze-stage.beeframeworks.co.uk/products",
    PRODUCT_DETAILS_URL: "https://doorstep-booze-stage.beeframeworks.co.uk/api/products"
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="development"){
  credentials = {
    API_URL: "http://localhost:8024/api",
    BASE_URL: "http://localhost:8024",
    PROMOTIONAL_API_URL: "http://localhost:8024/api/promotional/",
    PRODUCT_URL: "http://localhost:8024/products",
    PRODUCT_DETAILS_URL: "http://localhost:8024/api/products"
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="prod"){
  credentials = {
    API_URL: "https://academy.excelhealthcareservices.co.uk/api",
    BASE_URL: "https://academy.excelhealthcareservices.co.uk",
    PROMOTIONAL_API_URL: "https://academy.excelhealthcareservices.co.uk/api/promotional/",
    PRODUCT_URL: "https://academy.excelhealthcareservices.co.uk/products",
    PRODUCT_DETAILS_URL: "https://academy.excelhealthcareservices.co.uk/api/products"
  }
}

export default credentials;
