import React from 'react'
import { Alert } from "reactstrap";

const AccountConfirmation = ({response, message}) => {

  // eslint-disable-next-line
  String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  }

  return (
    <div>
      <header className="headerbg d-flex">
        <div className="container my-auto">
          <div className="row">
            <div className="offset-1 col-10 offset-lg-4 col-lg-4">
              <div id="search-form-div" className="container">
                <div className="row">
                  <div className="col-12 my-4">
                    <div className="fat-div" style={{textAlign: 'center'}}>
                    <Alert color={response ? "danger" : "success"}>
                      {message && message.capitalize()}
                    </Alert>
                    <div className="col-12 page-information exp">
                      {response ? (message === "Activation Link expired" ?
                        <div>
                          This link expired. <a href="/resend-activation-link" className="a-links"> Click here to request another activation link. </a>
                        </div>
                        :
                        <div>
                          The link to activate your account is invalid. Please check your inbox for a valid link.
                        </div> )
                        :
                        <div>
                          <div>Your account has been registered successfully.</div>
                          <div> Please <a href="/login" className="a-links"> click here to log in </a></div>
                        </div> }

                    </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </header>

      </div>
  )
}

export default AccountConfirmation
