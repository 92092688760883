import React, {Component} from 'react';
import Tab from 'react-bootstrap/Tab';
import {Helmet} from "react-helmet";

import {
  Col,
  Row
} from "reactstrap";

class AboutUs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Excel Academy - About Us</title>
          <link rel="canonical" href="https://www.academy.excelhealthcareservices.co.uk/about-us" />
          <meta name="description"
                content="UK Premier Import Service - A Global e-commerce company" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h2 className="headings"
                      style={{ color: '#000000', padding: "0px 0px 32px 0px"}}>
                    Who we are
                  </h2>
                  {/*<hr className="fancy-line" />*/}
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="help-department-heading stock-procurement-heading">
                   </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                          {/*<p className="how-to-steps" style={{fontSize: '14px'}}>About Us</p>*/}
                            <p className="page-information">
                              Established in December 2020 based in Leeds West Yorkshire Door Step Booze is an
                              alternative on-line shop specialised in selling to its customer beers, spirits,
                              soft drinks, hot and  cold snacks and other products in our online shop which
                              delivers to its customers refreshments after hours.
                              We had the vision of supplying and delivering restaurant quality to your Doorstep
                              at retail prices! We started this through advertising on our local Facebook pages.
                              The response was amazing! This led us to start up Excel Academy.
                            </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}

export default AboutUs;
