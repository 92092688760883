import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid recreating the Stripe object on every render.
const promise = loadStripe("pk_live_51OsLftBrDDtQowsXfb7H5SbLbDZPgIbzfgbttjiaoULbk7PRP1tRYJIpYTi61xnzPBLF2uZOfXVsBqOy1JuQ6VNN00MWgbAX5X");

// Initialize Stripe Elements
export default function StripeIndex(props) {

    const { order, onConfirm } = props;
    return (
        <div className="App">
            <Elements stripe={promise}>
                <CheckoutForm
                    order={order}
                    onConfirm={onConfirm}
                />
            </Elements>
        </div>
    );
}