const FontFaceObserver = require('fontfaceobserver')

const Fonts = () => {
  const link = document.createElement('link')
  link.href = 'https://fonts.googleapis.com/css2?family=Mulish:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i'
  link.rel = 'stylesheet'

  document.head.appendChild(link)

  const roboto = new FontFaceObserver('Mulish')

  roboto.load().then(() => {
    document.documentElement.classList.add('mulish')
  }).catch(() => {
    console.log("Font not ready")
  })
}

export default Fonts
