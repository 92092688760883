import React, {Component} from "react";
import SearchResGrid from "./searchResGrid";
import {searchAction} from "../../store/actions";
import { Loading } from './../LoadingComponent';
import {connect} from "react-redux";
import {compose} from "redux";
import {withCookies} from "react-cookie";
import {withRouter} from "react-router-dom";

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      res: [],
      keyword: "",
      currentResults: [],
      allResults: []
    };
  }

  componentDidMount() {
    const query = this.props.match.params.q
    const optionalCat = this.props.match.params.cat
    const {getAllProductsSuccess} = this.props.vehicleReducer;

    this.setState({keyword: query, allResults: getAllProductsSuccess})

    if (optionalCat) {
      this.props.filterByCat({cat: query, defaultList: getAllProductsSuccess})
    } else {

      if (query === "drinks") {
        this.props.filterByClass({proClass: 1, defaultList: getAllProductsSuccess})
        return;
      }
      if (query === "snacks") {
        this.props.filterByClass({proClass: 2, defaultList: getAllProductsSuccess})
        return;
      }
      if (query === "shop") {
        this.setState({currentResults: getAllProductsSuccess})
      }
      else {
        this.props.filterByValue({value: query, defaultList: getAllProductsSuccess})
      }
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.keyword !== this.props.match.params.q) {
      this.setState({ keyword: this.props.match.params.q });
      window.location.reload(false)
    }
    if (this.props.searchReducer.catSearch &&
      this.props.searchReducer.catSearch !== prevProps.searchReducer.catSearch) {
      this.setState({ currentResults: this.props.searchReducer.catSearch })
    }
    if (this.props.searchReducer.classSearch &&
      this.props.searchReducer.classSearch !== prevProps.searchReducer.classSearch) {
      this.setState({ currentResults: this.props.searchReducer.classSearch })
    }

    if (this.props.searchReducer.filterSearch &&
        this.props.searchReducer.filterSearch !== prevProps.searchReducer.filterSearch){
      this.setState({currentResults: this.props.searchReducer.filterSearch})
    }
  }

  render() {
    const {keyword} = this.state
    const { getAllProductsLoader } = false
    let searchResults = this.state.currentResults;

    return (
      <div ref={topElement => (this.topElement = topElement)}>
        <div className="container text-center" id="contactContainer">
          <div className="row" style={{background: 'white', paddingBottom: "64px"}}>
            <div className="container">
              {searchResults.length === 0 ? null:
                <div
                  style={{
                  fontWeight: 800,
                  textAlign: "left",
                  fontSize: "80%",
                  padding: "2px 4px 2px 16px"}}>
                  Results match for <span style={{color: "#ccac00"}}>"{keyword}"</span>
                </div>
              }
              {getAllProductsLoader
                ?
                <Loading/>
                :
                <div className="row">
                  { searchResults.length === 0 ?
                    <div
                      style={{
                        minHeight: "400px",
                        fontWeight: "bold",
                        paddingLeft: "10px"}}>
                      Oops, no exact matches found for "{keyword}" </div>
                    :
                    searchResults.map((data, index ) => {
                      if (data) {
                        return (
                          <SearchResGrid d={data} i={index}>
                          </SearchResGrid>
                        )
                      }
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  vehicleReducer: state.vehicleReducer,
  searchReducer: state.searchReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    filterByCat: payload =>
      dispatch(searchAction.filterByCat(payload)),
    filterByClass: payload =>
      dispatch(searchAction.filterByClass(payload)),
    filterByValue: payload =>
      dispatch(searchAction.filterByValue(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
  withCookies,
  withRouter
)(SearchList));



