// "beer", "cider", "spirits", "non-alcoholic", "wine", "tobacco"
export const prodLandingData = [
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "BEERS",
    "search_q": "beer",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/dsb-BEER.jpg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CIDERS",
    "search_q": "cider",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/dsb-CIDER.jpg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "SPIRITS",
    "search_q": "spirits",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/dsb-SPIRITS.jpg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "WINE & CHAMPAGNE",
    "search_q": "wine",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/dsb-WINE-CHAMPAGNE.jpg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "SNACKS & SOFT DRINKS",
    "search_q": "non-alcoholic",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/SOFT-DRINKS-MIXERS.jpg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "CIGARETTE, TOBACCO & VAPES",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "search_q": "tobacco",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/dsb-CIGARETTES-TOBACCO.jpg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  },
  {
    "url": "http://localhost:8263/api/products/10/",
    "id": 10,
    "upc": "000009",
    "title": "OFFERS",
    "price": 125.0,
    "description": "CMCT Womens Black Dress",
    "search_q": "offers",
    "categories": [
      "Women"
    ],
    "product_class": "Dresses",
    "slug": "cmct-womens-black-dress",
    "date_created": "2021-03-27T14:09:47.944224Z",
    "images": [
      {
        "id": 11,
        "original": require("../assets/images/cmct/drink-deals-mob.jpeg"),
        "caption": "",
        "display_order": 0,
        "date_created": "2021-03-27T14:09:48.280862Z",
        "remote_image": null,
        "product": 10
      }
    ]
  }
  ]