import React from 'react';
import {Alert, Button, Card, CardBody} from 'reactstrap';


const ConfirmOrder  = ({onMyAccount, onContinueShopping, error, notification}) => {
  return (
    <div className="bottom-out-space-padding">
      <div className="">
        <Card className="bg-light">

          <CardBody style={{color:"#393939"}} className="text-center">
            {notification &&
            <div style={{padding: "16px 0px 8px 0px"}}>
              <Alert color={error ? "danger" : "success"}>
                {notification}
              </Alert>
            </div>
            }
            <i className="fa fa-check-circle" style={{fontSize:"40px", color:"#009800"}}></i>
            <p></p>
            <div className="basket-page-information"> CONGRATULATIONS! <p>
              YOUR ORDER IS ACCEPTED </p>
            </div>
            <i className="far fa-envelope" style={{fontSize:"40px"}}></i>
            <p></p>
            <div>
              You will receive confirmation email shortly. <br/>
              <p>
              If you have more questions, please contact us on 07756 287251.</p>
            </div>
          </CardBody>
        </Card>
      </div>
        <div className="row mbtn">
          <div className="col-sm-6 text-center">
            <Button className="delete-basket-button" size="sm" onClick={onMyAccount.bind(this)}>My Account</Button>
          </div>
          <div className="col-sm-6 text-center">
            <Button className="delete-basket-button" size="sm" onClick={onContinueShopping.bind(this)}>Continue Shopping</Button>
          </div>
        </div>
    </div>
  );
}


export default ConfirmOrder;